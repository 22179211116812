/** автономная форма авторизации
 * может быть вставлена в другие связанные компоненты
 */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Popover, PopoverBody } from "reactstrap";

import { Alert } from "reactstrap";
import TelegramButton from "components/standart/mediaButtons";
import ReactHtmlParser from "react-html-parser";
import { authApi, userApi } from "reducers/api";
import { useAuthSockets } from "./authSockets";

const { auth } = authApi.actions;

const LoginForm = (props) => {
  const { settings = {} } = props.env || {};
  const { socketsRef } = useAuthSockets();

  const translate = useTranslate();

  const [codeRecieved, setCodeRecieved] = useState(null);
  const [error, setError] = useState(false);

  const [offerAccepted, setOfferAccepted] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const timeoutStore = useRef(false);
  useEffect(() => {
    submitHandler();
    return () => {
      clearTimeout(timeoutStore.current);
      setCodeRecieved(null);
    };
  }, []);

  const setTimer = (time) => {
    setSeconds(time);
    timeoutStore.current = setTimeout(() => {
      time > 0 && setTimer(time - 1);
    }, 1000);
  };

  const submitHandler = () => {
    props.telegramAuth(
      {},
      {
        onSuccess({ code }) {
          setCodeRecieved(code);
          setLoading(false);
          setTimer(60);
        },
        onRequest() {
          setLoading(true);
          setError(false);
          setCodeRecieved(null);
        },
        onFailure({ message, data = {} }) {
          setLoading(false);
          setError(message);
          if (data.remainSeconds) {
            setTimer(data.remainSeconds);
          }
        },
      }
    );
  };

  const [copied, setCopied] = useState(false);
  const tooltipTimeout = useRef();
  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const tooltipId = "telegram_code_block";

  return (
    <div className="text-center">
      <div className="form-group mb-4">
        {/* <div className="standart-header mb-3">{translate("auth_is_simple")}</div> */}

        {error ? (
          <Alert
            className="alert-danger"
            isOpen={true}
            // toggle={() => setError(false)}
          >
            {/* <div>{translate(error) || error}</div> */}
            {seconds ? (
              translate("auth_reSend", { seconds })
            ) : (
              <span className="url" style={{ cursor: "pointer" }} onClick={() => submitHandler()}>
                {translate("auth_reRequestCode")}
              </span>
            )}
          </Alert>
        ) : null}

        {codeRecieved ? (
          <div>
            <div className="standart-header mb-3">{translate("telegram_codeSend")}</div>
            <CopyToClipboard text={codeRecieved} onCopy={() => setCopied(true)}>
              <div className="large-header mb-3" id={tooltipId}>
                {codeRecieved}
              </div>
            </CopyToClipboard>

            {copied && (
              <Popover fade={true} placement="top" isOpen={copied} target={tooltipId}>
                <PopoverBody>{translate("telegram_code_copied")}</PopoverBody>
              </Popover>
            )}

            <div className="mb-3">
              <TelegramButton
                button={translate("telegram_bot_open")}
                className="py-3"
                target="_self"
                url={`tg://resolve?domain=${_.split(settings.telegramBot, "/")
                  .reverse()
                  .shift()}&text=${codeRecieved}`}
              />
            </div>

            {/* {ReactHtmlParser(
              translate("telegram_codeSent", {
                bot: _.split(settings.telegramBot, "/").reverse().shift(),
                code: codeRecieved,
              })
            )} */}
            {/* 
            <Alert className="alert-success">
              <br />
              {seconds ? (
                translate("auth_reSend", { seconds })
              ) : (
                <span className="url" style={{ cursor: "pointer" }} onClick={() => submitHandler()}>
                  {translate("auth_reRequestCode")}
                </span>
              )}
            </Alert>
            */}

            <div className="form-group mt-4">
              <div className="pretty p-icon p-fill mr-2">
                <input
                  id="offer_required"
                  value={true}
                  type="checkbox"
                  onChange={() => setOfferAccepted(!offerAccepted)}
                  checked={offerAccepted}
                />
                <div className="state">
                  <i className="icon fa fa-check" />
                  <label />
                </div>
              </div>

              <label className="d-inline" htmlFor="offer_required">
                {translate("auth_offer_required")}
              </label>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locales: state.locales,
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  telegramAuth: auth.post("/telegram/bot"),
})(LoginForm);
