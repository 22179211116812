import _ from "lodash";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import nl2br from "react-nl2br";
import { useTranslate } from "react-redux-multilingual";

import { frontendApi, fileUrl } from "reducers/api";
import { SmartImage, useWindowSize } from "components/standart";
import { SwiperBlock } from "components/standart/swiper-block";


import { CastInfoSmall } from "./cast";
import { TranslationsLinkBlock } from "../live";

const { api } = frontendApi.actions;

const SpecialsCastsBlock = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const { env } = useSelector((state) => state.frontend);

  const { specialsBlock = {} } = env.settings;

  const [isLoading, setLoading] = useState(false);
  const [specialsCasts, setSpecialsCasts] = useState([]);

  const size = useWindowSize();

  const selectCastId = (castId) => {
    navigate(castId ? `/?specialId=${castId}` : "/?");
  };

  useEffect(() => {
    props.getSpecialsCasts(
      { query: { lastOnly: true } },
      {
        onSuccess(body) {
          setSpecialsCasts(body);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
      }
    );
  }, []);

  const setCurrentSlideHandler = (value) => {
    // currentSlide.current = value;
    setCurrentSlide(value);
    // setTopHeader(topCasts[value].keywords);
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const SwiperContent = React.useMemo(() => {
    return (
      <SwiperBlock
        id="specials-casts-block"
        slidesPerView={size.width > 750 ? 3 : 2}
        setCurrentSlideHandler={setCurrentSlideHandler}
        slides={[<TranslationsLinkBlock />].concat(
          _.map(specialsCasts, (cast, index) => {
            return (
              <div key={index} style={{ cursor: "pointer" }} onClick={() => selectCastId(cast._id)}>
                <CastInfoSmall
                  cast={cast}
                  key={index}
                  // showFavorite={true}
                  // showPinned={true}
                  // defaultImageId={cast.channel?.fileId}
                >
                  {/* <div className="py-2">
                            <ShowCastDuration {...cast} />
                          </div> */}
                </CastInfoSmall>
              </div>
            );
          })
        )}
      />
    );
  }, [specialsCasts, size]);

  return isLoading ? null : (
    <div className="pb-3 text-center">
      <div className="large-header pt-2 pb-2">{specialsBlock.title}</div>

      {_.trim(specialsBlock.content) ? (
        <div className="standart-header content-html pb-4">{nl2br(specialsBlock.content)}</div>
      ) : (
        <div className=" pb-2" />
      )}

      {SwiperContent}
    </div>
  );
};

export default connect(null, {
  getSpecialsCasts: api.get("/specials"),
})(SpecialsCastsBlock);
