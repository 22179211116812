import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import nl2br from "react-nl2br";
import ReactHtmlParser from "react-html-parser";
import Col3Container from "components/standart/col3Container";
import { frontendApi, userApi } from "reducers/api";
import { LoadingIcon } from "components/standart";

const { api: apiFrontend } = frontendApi.actions;
const { api: apiUser, session } = userApi.actions;

const CategoriesModal = (props) => {
  const { navi } = props;
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { session } = useSelector((state) => state.user);
  const { env } = useSelector((state) => state.frontend);

  const { landings = {} } = env;
  const landingData = { ...landings.channels };
  const { title = "", content = "", isHtml } = { ...landingData?.intro };

  const [categoriesId, setCategoriesId] = useState([]);
  const [categories, setCategories] = useState(null);
  const [currentCategoryRequest, setCurrentCategoryRequest] = useState(null);

  const categoriesDivId = "categories_block";

  // отреагируем на значения учетной записи
  useEffect(() => {
    setCategoriesId(session.account ? session.categoriesId : []);
  }, [session.account]);

  useEffect(() => {
    props.getCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
        },
      }
    );

    return () => {
      // props.modalRef.current.removeEventListener("scroll", handleScroll);
    };

    return () => {
      // history.replace('?')
    };
    // loadChannels(0);
  }, []);

  const toggleUserCategory = (categoryId) => {
    const requestHandler = !categoriesId.includes(categoryId)
      ? props.addUserCategory
      : props.deleteUserCategory;
    requestHandler(
      { body: { categoryId } },
      {
        onSuccess() {
          setCurrentCategoryRequest(null);
          props.getUser();
        },
        onRequest() {
          setCurrentCategoryRequest(categoryId);
        },
      }
    );
  };

  const commonButtonClass =
    "btn btn-rounded btn-sm -font-weight-normal -standart-header border-secondary mr-2 mb-2";
  const activeButtonClass = "btn-dark active";

  const channelsBlock = "channels-block";

  return categories ? (
    <div className="position-relative">
      <Col3Container>
        <div id={channelsBlock}>
          <div className="text-center large-header mb-3">{nl2br(title)}</div>
          <div className="text-center standart-header mb-4">
            {isHtml ? ReactHtmlParser(content) : nl2br(content)}
          </div>
          <div
            id={categoriesDivId}
            className="-list-group -list-group-horizontal -text-nowrap -overflow-auto favorites-list position-relative mb-3"
          >
            {categories.map((category, index) => {
              const isUserCategory = categoriesId.includes(category._id);
              return (
                <span
                  className={`${commonButtonClass} ${isUserCategory ? activeButtonClass : ""}`}
                  key={index}
                  onClick={() => toggleUserCategory(category._id)}
                  // onClick={() => setCategory(category)}
                  // onClick={() => goToCategoryHandler(category)}
                >
                  {category.name}
                  {currentCategoryRequest === category._id ? (
                    <LoadingIcon className="ml-2" />
                  ) : (
                    <>{isUserCategory ? <i className="fa fa-check ml-2" /> : ""}</>
                  )}
                  {/* ({category.channelsCount}) */}
                </span>
              );
            })}
          </div>
        </div>
      </Col3Container>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, {
  getChannels: apiFrontend.get("/channels"),
  checkChannelsNew: apiFrontend.get("/channels/checkNew"),
  getCategories: apiFrontend.get("/channels/categories"),
  addUserCategory: apiUser.post("/account/categories/add"),
  deleteUserCategory: apiUser.post("/account/categories/delete"),
  ...session.actions
})(CategoriesModal);
