import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { frontendApi } from "reducers/api";
import { LoadingIcon } from "components/standart";
import { setPlaylistMessages, setPlaylistChannels, setPlaylistCastId } from "actions";
import { useTranslate } from "react-redux-multilingual";

import PlayerListVisual from "components/common/player/visual";

const { api: apiFrontend } = frontendApi.actions;

const CastMessages = (props) => {
  const { cast } = props;
  const translate = useTranslate();

  const [messages, setMessages] = useState([]);
  const [channels, setChannels] = useState({});

  useEffect(() => {
    props.getCastMessages(
      { query: { castId: cast._id } },
      {
        onSuccess(body) {
          setMessages(body);
          const channels = {};
          _.each(body, (message) => {
            channels[message.channelId] = message.channel;
          });
          setChannels(channels);
          props.setPlaylistCastId(cast._id);
          props.setPlaylistMessages(_.map(body, (value) => ({ ...value, castId: cast._id })));
          props.setPlaylistChannels(_.values(channels));
        },
      }
    );
  }, [cast]);

  return _.size(channels) && _.size(messages) ? (
    <div>
      <div className="large-header pb-3">{translate("messages")}</div>
      <PlayerListVisual
        {...{ channels, messages, cast }}
        // collapseLimit={10}
      />
    </div>
  ) : (
    <LoadingIcon />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getCastMessages: apiFrontend.get("/casts/messages"),
  setPlaylistMessages,
  setPlaylistChannels,
  setPlaylistCastId,
})(CastMessages);
