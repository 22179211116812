import React, { useState, useCallback, useEffect } from "react";
import nl2br from "react-nl2br";
import { useTranslate } from "react-redux-multilingual";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import TelegramButton from "./mediaButtons";
import Col3Container from "./col3Container";

const ExtraBottomSection = (props) => {
  const { env } = useSelector((state) => state.frontend);

  const { landings = {} } = env;
  const landingData = { ...landings.index };

  return (
    <>
      {landingData.extra?.title || landingData.extra?.content ? (
        <section className="bg-grey">
          <div className="container pt-1">
            <Col3Container>
              <div className="text-center">
                <div className="large-header mb-3">{nl2br(landingData.extra?.title)}</div>
                <div className="standart-header">
                  {landingData.extra?.isHtml
                    ? ReactHtmlParser(landingData.extra?.content)
                    : nl2br(landingData.extra?.content)}
                </div>
                <div className="my-4">
                  <TelegramButton className="btn py-3 standart-header" />
                </div>
              </div>
            </Col3Container>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default connect(null, {})(ExtraBottomSection);
