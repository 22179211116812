import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { useNavigate } from "react-router-dom";
import { SwiperBlock } from "components/standart/swiper-block";

import { userApi } from "reducers/api";
import { LoadingIcon } from "components/standart";

import { CastInfoSmall } from "../casts/cast";
import nl2br from "react-nl2br";

const { api } = userApi.actions;
const getCasts = api.get("/account/casts-favorites");

const FavoritesCasts = (props) => {
  const { size } = props;
  const translate = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [casts, setCasts] = useState([]);
  const [titles, setTitles] = useState([]);

  const extractTitles = (casts) => {
    // если число выпусков больше 2
    const extractedTitles = [];
    if (casts.length > 2) {
      extractedTitles.push(..._.slice(casts[0].keywords, 0, 1));
      extractedTitles.push(..._.slice(casts[1].keywords, 0, 1));
      extractedTitles.push(..._.slice(casts[2].keywords, 0, 1));
    } else if (casts.length > 1) {
      // если число выпусков больше 1
      extractedTitles.push(..._.slice(casts[0].keywords, 0, 2));
      extractedTitles.push(..._.slice(casts[1].keywords, 0, 1));
    } else if (casts.length > 0) {
      // если число выпусков равно 1
      extractedTitles.push(..._.slice(casts[0].keywords, 0, 3));
    }
    setTitles(extractedTitles);
  };

  useEffect(() => {
    dispatch(
      getCasts(
        {},
        {
          onSuccess(body) {
            setCasts(body);
            extractTitles(body);
            setLoading(false);
          },
          onFailure() {
            setLoading(false);
          },
          onRequest() {
            setLoading(true);
          },
        }
      )
    );
  }, []);

  const selectCastId = (castId) => {
    navigate(castId ? `/?castId=${castId}&favorites=1` : "?");
  };
  const { env } = useSelector((state) => state.frontend);
  const { favoritesDescription } = env.settings;

  return (
    <div>
      {isLoading ? null : (
        <>
          {casts.length ? (
            <div className="pb-3 text-center">
              <div className="large-header  pt-2 pb-2">{translate("favorites")}</div>

              {_.trim(favoritesDescription) ? (
                <div className="text-center standart-header pb-4">
                  {nl2br(favoritesDescription)}
                  {/* {translate("favorites_new_casts", { value: casts.length })} */}
                  {/* {_.concat(titles, [""]).join(". ")} */}
                </div>
              ) : (
                <div className="pb-2" />
              )}

              <div>
                <SwiperBlock
                  id="favorites-casts"
                  slidesPerView={size.width > 750 ? 3 : 2}
                  slides={_.map(casts, (cast, index) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => selectCastId(cast._id)}
                      >
                        <CastInfoSmall
                          cast={cast}
                          key={index}
                          defaultImageId={cast.channel?.fileId}
                        />
                      </div>
                    );
                  })}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default connect(null, {})(FavoritesCasts);
