import session from "./user/session";
import auth from "./auth/auth";

let clearInProcessing = false;
export const safeClearAuthSession = (store) => {
  const completeLogout = () => {
    clearInProcessing = false;
    document.location.href = "/";
  };
  !clearInProcessing &&
    store.dispatch(
      session.actions.logout(
        {},
        {
          onRequest() {
            clearInProcessing = true;
          },
          onSuccess() {
            store.dispatch(
              auth.actions.logout(
                {},
                {
                  onSuccess: completeLogout,
                  onFailure: completeLogout,
                }
              )
            );
          },
        }
      )
    );
};
