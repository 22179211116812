import _ from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePageVisibility } from "react-page-visibility";
import { frontendApi } from "reducers/api";
import { setPlayerMessages } from "actions";

const { api } = frontendApi.actions;
const getChannelMessages = api.get("/channels/messages");

export const useCheckVisible = (props) => {
  //
  const dispatch = useDispatch();
  const isVisible = usePageVisibility();
  const player = useSelector((state) => state.player);
  // поскольку здесь всегда будут ожидаться только новые сообщения
  const getNewMessages = useCallback(() => {
    const afterDate = _.last(player.messages).date;
    const channelId = _.join(player.channelsId, ",");
    const query = { channelId, afterDate };
    dispatch(
      getChannelMessages(
        { query },
        {
          onSuccess(data) {
            // добавим сообщения строго в конец и переустановим их в плеере
            const messages = [...player.messages, ...data];
            dispatch(setPlayerMessages(messages));
          },
        },
      ),
    );
  }, [player]);

  useEffect(() => {
    if (isVisible && _.size(player.messages)) {
      // getNewMessages();
    }
  }, [isVisible]);

  const visibleChecker = useRef(undefined);

  // useEffect(() => {
  //   visibleChecker.current = new NoSleep();
  // }, []);

  return { visibleChecker };
};
