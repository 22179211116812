/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import { combineReducers } from "redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import frontendReducers from "./reducers";
import { safeClearAuthSession } from "../special";

export default frontendReducers;

// типовой редюсер для API
export function reducer(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const frontendApiReducer = combineReducers({
  api: reducer,
  ...frontendReducers.reducers,
});

export const frontendApiMiddleware =
  (store) =>
  (next) =>
  (action = { type: "" }) => {
    const result = next(action);
    const { payload } = action;

    if (_.get(payload, "message") === "Failed to fetch") {
      // console.log("enable lock screen");
    }

    // если по какой-то причине вернулся 401 или 303, то полностью закроем сессию и удалим токены
    if ([401, 303].includes(_.get(payload, "status"))) {
      safeClearAuthSession(store);
      return false;
    }

    return result;
  };

export const middlewares = [frontendApiMiddleware, ...frontendReducers.middlewares];
