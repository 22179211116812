import _ from "lodash";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop } from "components/standart";
import { setNavi } from "actions";
import { BlockSection, IntroSection } from "components/standart/LandingSections";
import { fileUrl } from "reducers/api";

const AdLanding = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);

  const { meta = {}, settings = {} } = env;

  useEffect(() => {
    scrollTop();
    props.setNavi("/");

    const startInstagramVisual = () => {
      try {
        window.instgrm.Embeds.process();
      } catch (e) {
        setTimeout(startInstagramVisual, 500);
      }
    };
    setTimeout(startInstagramVisual, 500);
  }, []);
  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.ad || {};
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <IntroSection type="ad" />
      <BlockSection type="ad" blockName="block1" />
      <BlockSection type="ad" blockName="block2" />
      <BlockSection type="ad" blockName="block3" paddingBottom={5} />
      <BlockSection
        type="ad"
        blockName="block4"
        bgColor="bg-grey"
        paddingTop={5}
        paddingBottom={5}
      />
      <BlockSection type="ad" blockName="block5" paddingTop={5}>
        <div className="my-4">
          <a href={settings.telegram} target="_blank" className="btn btn-solid standart-header">
            <i className="fab fa-telegram-plane mr-2" />
            {translate("send_request")}
          </a>
        </div>
      </BlockSection>
      {/* <ContactsSection data={settings?.adContacts}  paddingTop={5}/> */}
      <div className="my-5" />
    </>
  );
};

export default connect(null, {
  setNavi,
})(AdLanding);
