import _ from "lodash";
import { applyUrlParams } from "./init";
import ReconnectingWebSocket from "reconnecting-websocket";
import { accessToken, sessionToken } from "./token";

export const { DATA_SOCKET } = process.env;
if (!DATA_SOCKET) throw "DATA_SOCKET not defined";

export class SocketsController {
  url;
  params;
  onmessage;
  onconnect;
  ws;

  constructor(url, { onmessage, onconnect }, params) {
    this.url = url;
    this.params = params;
    this.onmessage = onmessage;
    this.onconnect = onconnect;
  }

  connect() {
    if (this.ws && this.ws.readyState <= 1) return;
    const { url, params, onmessage, onconnect } = this;

    const protocol = _.toString(localStorage.getItem(sessionToken));
    const ws = new ReconnectingWebSocket(DATA_SOCKET + applyUrlParams(url, params), protocol, {
      maxRetries: 10000,
    });

    ws.onopen = function () {
      ws.send(`socket connected ${url}`);
      // console.log("Socket is opened.", url);
      if (typeof onconnect === "function") {
        onconnect();
      }
    };

    onmessage && (ws.onmessage = onmessage);

    /*
    ws.onclose = function (e) {
      console.log("Socket is unsafety closed.", e);
      setTimeout(function () {
        self.connect();
        // sockets.connect(url, { params }, { onmessage });
      }, 1000);
    };

    ws.onerror = function (err) {
      console.error("Socket encountered error: ", err.message, "Closing socket");
      ws.close();
    };
    */

    this.ws = ws;
  }

  safeClose() {
    if (this.ws) {
      this.ws.onclose = function (e) {
        // console.log("Socket is safety closed.", e);
      };
      this.ws.close();
    }
  }
}
