import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { ChannelBlock } from "components/standart/channelBlock";
import { history } from "store";
import { userApi, frontendApi } from "reducers/api";
import Col3Container from "components/standart/col3Container";
import { LoadingIcon } from "components/standart";

const { api, session } = userApi.actions;
const { api: frontend } = frontendApi.actions;

const PlaylistModal = (props) => {
  const translate = useTranslate();

  const { session } = props;
  const [favorites, setFavorites] = useState(session.favorites);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    props.getChannels(
      { query: { id: _.join(session.channelsId, ",") } },
      {
        onSuccess(body) {
          setFavorites(body);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
      },
    );

    return () => {
      props.getUser();
    };
  }, []);

  return (
    <Modal isOpen={true} size="md" centered wrapClassName="fullscreen-modal" toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}></ModalHeader>
      <ModalBody>
        <Col3Container>
          <div className="large-header text-center mb-5">{translate("favorites_edit")}</div>
          <div className="text-center">{isLoading ? <LoadingIcon /> : null}</div>
          <div className="row">
            {_.map(favorites, (channel, index) => {
              return <ChannelBlock channel={channel} className="col-4 col-lg-3" key={index} />;
            })}
          </div>
          <div className="py-5" />
          <div className="py-3" />
        </Col3Container>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  session: state.user.session,
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  ...session.actions,
  getChannels: frontend.get("/channels"),
})(PlaylistModal);
