import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { safeAddPlaylistMessage, safeAddPlayerMessage, setPlayerAdvert } from "actions";
import { safeDeletePlaylistMessages, safeDeletePlayerMessages } from "actions";
import { SocketsController } from "reducers/api/sockets";
import { usePageVisibility } from "react-page-visibility";

export const useSockets = (props) => {
  //
  const dispatch = useDispatch();
  const isVisible = usePageVisibility();

  const onmessage = function (msg) {
    const data = JSON.parse(msg.data);
    if (data.advertCompanyId) {
      // тут следует добавить рекламу в state плеера
      // setCurrentAdvert(data);
      // console.log("next advert is", data);
      dispatch(setPlayerAdvert(data));
    }
    /*
    // закомментируем обработку новых сообщений из сокетов
    if (data.channelId) {
      if (data.isDeleted) {
        // если признак "удалено", то ожидается список ident, которые следует убрать из плейлиста
        dispatch(safeDeletePlaylistMessages(data));
        dispatch(safeDeletePlayerMessages(data));
      } else {
        dispatch(safeAddPlaylistMessage(data));
        dispatch(safeAddPlayerMessage(data));
      }
    }
    */
  };

  const socketsRef = useRef(new SocketsController("/subscribe", { onmessage }));

  const connectSocket = () => {
    socketsRef.current.connect();
  };
  const destorySocket = () => {
    socketsRef.current.safeClose();
  };
  useEffect(() => {
    // console.log("init component and socket subscribe");
    try {
      connectSocket();
      return () => {
        destorySocket();
      };
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    // console.log("on visible socket", socketsRef.current.ws);
    if (isVisible && socketsRef.current.ws?.readyState > 1) {
      // connectSocket();
    }
  }, [isVisible]);

  return { socketsRef };
};
