import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { useNavigate } from "react-router-dom";
import { SmartImage } from "components/standart";
import { fileUrl } from "reducers/api";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { frontendApi } from "reducers/api";

import CastsSearchModal from "./search";

const categoriesDivId = "casts-categories-container";
const { api: apiFrontend } = frontendApi.actions;

const commonButtonClass =
  "btn btn-rounded btn-sm -font-weight-normal -standart-header text-nowrap border-secondary mr-2 mb-2";

const CategoriesList = (props) => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    props.getCastsCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
        },
      }
    );
  }, []);

  const selectCastCategoryId = (categoryId) => {
    navigate(categoryId ? `/?categoryId=${categoryId}` : "/?");
  };

  const selectCastCategory = (category) => {
    navigate(category ? `/?category=${category.alias}` : "/?");
  };

  const { currentCategoryId, currentCategory } = props;

  return (
    <div>
      <div className="d-flex">
        <div
          id={categoriesDivId}
          className="flex-grow-1 list-group list-group-horizontal -text-nowrap overflow-auto favorites-list position-relative mb-2"
        >
          <CategoryButton
            {...{
              category: { name: "Все", _id: null },
              currentCategoryId,
              currentCategory,
              // clickHandler: () => selectCastCategoryId(null),
              clickHandler: () => selectCastCategory(null),
            }}
          />

          {categories.map((category, index) => {
            // const clickHandler = () => selectCastCategoryId(category._id);
            const clickHandler = () => selectCastCategory(category);
            return (
              <CategoryButton
                {...{ category, currentCategory, currentCategoryId, clickHandler }}
                key={index}
              />
            );
          })}
        </div>
        {/* 
        <div>
          <ExtraButton {...{ categories, currentCategoryId }} onClick={props.onClick} />
        </div>
        */}
      </div>
    </div>
  );
};

const CategoryButton = (props) => {
  const { category, currentCategoryId, currentCategory, clickHandler } = props;
  const [extraClass, setExtraClass] = useState("");
  const buttonId = `category_button_${category._id}`;
  const btnClass =
    (!currentCategory && currentCategoryId === category._id) ||
    (!currentCategoryId && currentCategory === category.alias)
      ? "btn-dark active"
      : "";
  useEffect(() => {
    const categoryButton = document.getElementById(buttonId);
    const categoryBlock = document.getElementById(categoriesDivId);
    // скроем кнопки, если они выходят за пределы контейнера
    if (
      currentCategoryId !== category._id &&
      categoryButton.offsetLeft + categoryButton.offsetWidth * 0.38 > categoryBlock.offsetWidth
    ) {
      // setExtraClass("d-none");
    }
    //
  }, [currentCategoryId]);
  return (
    <span
      className={`${commonButtonClass} ${btnClass} ${extraClass}`}
      onClick={clickHandler}
      id={buttonId}
    >
      {/* {currentCategory} {currentCategoryId} */}
      {category.name}
      {category.fileId && false ? (
        <SmartImage
          src={fileUrl(category.fileId, "preview.jpg")}
          style={{
            objectFit: "cover",
            width: "16px",
            height: "16px",
            marginBottom: "2px",
          }}
          className="ml-1 rounded rounded-circle border border-1"
        />
      ) : null}
    </span>
  );
};

const ExtraButton = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);

  const toggleModal = () => setModalOpen(!modalOpen);
  const translate = useTranslate();
  useEffect(() => {
    //
  }, []);
  return (
    <>
      <span className={`${commonButtonClass}`} onClick={toggleModal}>
        {/* {translate("more")} */}
        <i className="far fa-search mx-2" />
      </span>
      {modalOpen ? (
        <Modal
          isOpen={true}
          toggle={toggleModal}
          wrapClassName="fullscreen-modal"
          innerRef={modalRef}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <CastsSearchModal modalRef={modalRef} {...props} closeHandler={toggleModal} />
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  getCastsCategories: apiFrontend.get("/casts/categories"),
})(CategoriesList);
