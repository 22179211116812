import React from "react";
import { useTranslate } from "react-redux-multilingual";
import { Helmet } from "react-helmet-async";

const Error404 = (props) => {
  const translate = useTranslate();
  return (
    <>
      <Helmet>
        <title>{translate("page_not_found")}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section className="section-b-space">
        <div className="collection-wrapper">
          <div className="container text-center">
            <h1>404</h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error404;
