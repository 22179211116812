import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "react-redux-multilingual";
import Col3Container from "components/standart/col3Container";
import qs from "query-string";

import { LoadingIcon, scrollTop } from "components/standart";
import { frontendApi, fileUrl, userApi } from "reducers/api";
import CategoriesList from "../casts/categories";
import ExtraBottomSection from "components/standart/extraBottomSection";

import CastData from "../casts/cast";

import CastsListData from "./casts-data";
import Error404 from "../error404";

const { api } = frontendApi.actions;
const { api: usersApi } = userApi.actions;

const ChannelPage = (props) => {
  const { alias } = useParams();
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const { env } = props;
  const { settings } = env;
  const [showArchive, setShowArchive] = useState(false);
  const [channelData, setChannelData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);

  const [castId, setCastId] = useState(null);
  const [castInfo, setCastInfo] = useState(null);
  const [castLoading, setCastLoading] = useState(null);

  const selectCastId = (castId) => {
    navigate(castId ? `?castId=${castId}` : "?");
  };

  useEffect(() => {
    if (castId || !showArchive) {
      const query = castId ? { castId } : { alias };
      props.getCastsInfo(
        { query },
        {
          onRequest() {
            setCastLoading(true);
          },
          onSuccess(body) {
            setCastInfo(body);
            setCastLoading(false);
          },
          onFailure() {
            setCastLoading(false);
            if (castId) {
              selectCastId(null);
            } else {
              setCastInfo(null);
            }
          },
        }
      );
    } else {
      setCastInfo(null);
    }
  }, [castId, showArchive]);

  const [categoriesOnly, setCategoriesOnly] = useState(false);

  useEffect(() => {
    const { archive, castId } = qs.parse(location.search);
    setShowArchive(!_.isUndefined(archive));
    setCastId(castId);
  }, [location.search]);

  useEffect(() => {
    // setTimeout(() => scrollTop(), 100);
  }, []);

  const [isLoaded, setLoaded] = useState(false);

  const loadChannel = (alias) => {
    props.getChannel(
      { query: { alias } },
      //
      {
        onSuccess(body) {
          setChannelData(body);
          setCategoriesOnly(false);
          setLoaded(true);
        },
        onRequest() {
          setLoaded(false);
        },
        onFailure() {
          setLoaded(true);
          // loadCategory(alias); // убран показ категорий
        },
      }
    );
  };

  const loadCategory = (alias) => {
    props.getCategory(
      { query: { alias } },
      //
      {
        onSuccess(body) {
          setCategoryData(body);
          setCategoriesOnly(true);
          setLoaded(true);
        },
        onRequest() {
          setLoaded(false);
        },
        onFailure() {
          setLoaded(true);
        },
      }
    );
  };

  useEffect(() => {
    setChannelData(null);
    setCategoryData(null);
    loadChannel(alias);
  }, [alias]);

  useEffect(() => {}, [isLoaded]);

  // useEffect(() => {
  //   console.log("categoryData/channelData", { categoryData, channelData });
  // }, [channelData, categoryData]);

  return isLoaded ? (
    channelData ? (
      <>
        {/*SEO Support*/}
        <Helmet>
          <title>
            {translate("title_index")} | {channelData.name}
          </title>
          <meta property="og:title" content={`${channelData.name}`} />

          <meta property="og:description" content={`${settings.channelsDescription}`} />
          <meta property="description" content={`${settings.channelsDescription}`} />

          <meta property="og:image" content={fileUrl(channelData.fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(channelData.fileId, "image.jpg")} />

          <meta property="og:locale" content={`ru_RU`} />
        </Helmet>

        <section className="bg-white py-0 my-0">
          <div className="container py-0">
            <Col3Container>
              {showArchive ? (
                <CastsListData
                  query={{ channelId: channelData._id }}
                  defaultImageId={channelData.fileId}
                />
              ) : (
                <>
                  {castLoading ? (
                    <div className="text-center p-5">
                      <LoadingIcon />
                    </div>
                  ) : (
                    <>
                      {castInfo ? (
                        <CastData cast={castInfo} defaultImageId={channelData.fileId} />
                      ) : (
                        <h1>404</h1>
                      )}
                    </>
                  )}
                </>
              )}
            </Col3Container>
          </div>
        </section>
        <ExtraBottomSection />
      </>
    ) : categoryData ? (
      <>
        <Helmet>
          <title>
            {translate("title_index")} | {categoryData.name}
          </title>
          <meta property="og:title" content={`${categoryData.name}`} />
          <meta property="og:locale" content={`ru_RU`} />
        </Helmet>

        <section className="bg-white py-0 my-0">
          <div className="container py-0">
            <Col3Container>
              <CastsListData query={{ categoryId: categoryData._id, categoriesOnly }} />
            </Col3Container>
          </div>
        </section>
        <ExtraBottomSection />
      </>
    ) : (
      <Error404 />
    )
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  // ...
  // sessionStore: state.user.session,
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  getChannel: api.get("/channels/info"),
  getCastsInfo: api.get("/casts/info"),
  getCategory: api.get("/categories/info"),
})(ChannelPage);
