import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Auth from "./index";
import { useTranslate } from "react-redux-multilingual";
import Col3Container from "components/standart/col3Container";

const AuthModal = (props) => {
  const translate = useTranslate();

  return (
    <Modal
      isOpen={true}
      // wrapClassName="fullscreen-modal"
      centered
      size="xl"
      toggle={props.toggleModal}
    >
      <ModalHeader toggle={props.toggleModal}></ModalHeader>
      <ModalBody>
        <Col3Container>
          <div className="text-center">
            <div className="large-header pb-2">{translate("auth_title")}</div>
            <Auth />
          </div>
        </Col3Container>
      </ModalBody>
    </Modal>
  );
};

export default AuthModal;
