import _ from "lodash";
import React from "react";
import { fileUrl } from "reducers/api";
import PartnerLogoBlock from "components/standart/partnerLogoBlock";
import { useTranslate } from "react-redux-multilingual";
import nl2br from "react-nl2br";
import ReactHtmlParser from "react-html-parser";
import { ContactsButtons } from "./contactsButtons";
import Col3Container from "./col3Container";

export const ContactsSection = (props) => {
  const translate = useTranslate();
  const { data } = props;
  // const { env } = useSelector((state) => state.frontend);
  // const { landings = {} } = env;
  // const landingData = { ...landings[props.type] };
  // const { title = "", content = "" } = { ...data };
  const { title = "", content = "", isHtml } = { ...data };

  return title || content ? (
    <section className={`bg-white pb-${props.paddingBottom || 0} pt-${props.paddingTop}`}>
      <div className={`mb-${props.marginTop}`} />
      <div className="container py-1">
        <Col3Container>
          <div className="text-center">
            <div className="large-header pb-2">{ReactHtmlParser(title)}</div>
            {/* <div className="my-4" /> */}
            {/* <PartnerLogoBlock partner={{ fileId: data.imageId }} size="large" type="image.jpg" /> */}

            {/* <img
              src={fileUrl(data.imageId, "download")}
              className="rounded-circle border border-1"
            /> */}
            <div className="standart-header content-html pb-2">
              <div className="standart-header content-html">{ReactHtmlParser(content)}</div>
            </div>

            <div className="my-3">
              <a href={data.telegram} target="_blank" className="btn btn-solid standart-header">
                <i className="fab fa-telegram-plane mr-2" />
                {translate("write_us")}
              </a>
            </div>

            {/* <ContactsButtons data={data} /> */}
          </div>
        </Col3Container>
      </div>
      <div className={`mb-${props.marginBottom}`} />
    </section>
  ) : null;
};
