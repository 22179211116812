import _ from "lodash";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { useTranslate } from "react-redux-multilingual";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setPlayerMessages,
  setPlayerVisible,
  setPlayerDisabled,
  setPlayerSound,
  setPlayerPlay,
  setPlayerChannels,
  togglePlayerPlay,
} from "actions";
import { Route, Routes, useResolvedPath, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Auth from "views/auth";
import FavoritesIndicator from "components/standart/indicator";

import PlaylistModal from "./modal";
// import ChannelsModal from "../channels/modal";
import CategoriesModal from "../channels/categories-modal";
import { useToggle } from "react-use";
import Col3Container from "components/standart/col3Container";

const PlaylistButtons = (props) => {
  const url = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const translate = useTranslate();
  // const { channels, messages } = props;

  const [isFavorite, setIsFavorite] = useState(null);
  const [isPlayed, setPlayed] = useState(null);
  const [channelsModal, toggleModal] = useToggle(false);

  const closeModalHandler = () => {
    props.updateState();
    toggleModal();
  };

  const player = useSelector((state) => state.player);
  const playlist = useSelector((state) => state.playlist);
  const { session } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const modalRef = useRef(null);

  const gotoPath = (path) => {
    navigate([url, path].filter(Boolean).join("/"));
  };

  useEffect(() => {
    setPlayed(isFavorite && player.play);
  }, [isFavorite, player.play]);

  useEffect(() => {
    const playerChannelsId = player.channelsId;
    const currentChannelsId = playlist.channelsId;
    setIsFavorite(_.isEqual(playerChannelsId, currentChannelsId));
  }, [player.channelsId, playlist.channelsId]);

  const playButtonHandler = useCallback(() => {
    if (player.disabled) {
      return null;
      // dispatch(setPlayerDisabled(false));
    }

    if (isFavorite) {
      // dispatch(setPlayerPlay(false));
      dispatch(togglePlayerPlay());
    } else {
      dispatch(setPlayerChannels(playlist.channels));
      dispatch(setPlayerMessages(playlist.messages));
      dispatch(setPlayerPlay(true));
      dispatch(setPlayerVisible(true));
      // тут пока заглушка вида "если текущее сообщение в плеере не из этого канала, то начинаем играть первое"
      dispatch(setPlayerSound(playlist.messages[0]));
    }
  }, [player, playlist, isPlayed]);

  const fontSize = "1.5rem";
  const paddingTop = "18px";
  return (
    <div>
      <div className="partner-logos d-flex content-justify-center">
        <div className="ml-auto my-auto mr-1">
          <button
            className="btn btn-solid py-3 standart-header text-nowrap position-relative"
            onClick={playButtonHandler}
          >
            <i className={`fas ${isPlayed ? "fa-pause" : "fa-play"} mr-2`} />
            {translate("playlist_play")}
            {/* <div className="position-absolute" style={{top: -7, right: -0}}><FavoritesIndicator /></div> */}
          </button>
        </div>
        <div className="ml-2 mr-auto">
          <button
            className="btn btn-outline py-3 standart-header text-nowrap"
            // onClick={() => navigate("/channels")}
            onClick={toggleModal}
          >
            <i className={`fas ${session.account || true ? "fa-edit" : "fa-edit"} mr-2`} />
            {translate(session.account || true ? "playlist_edit" : "playlist_create")}
          </button>
        </div>
      </div>

      {channelsModal ? (
        <Modal
          wrapClassName="fullscreen-modal"
          toggle={closeModalHandler}
          innerRef={modalRef}
          isOpen={true}
        >
          <ModalHeader toggle={closeModalHandler}></ModalHeader>
          <ModalBody>
            {session.account ? (
              <CategoriesModal modalRef={modalRef} closeHandler={closeModalHandler} />
            ) : (
              <Col3Container>
                <div className="large-header text-center">{translate("auth_title")}</div>
                <Auth />
              </Col3Container>
            )}
          </ModalBody>
        </Modal>
      ) : null}

      {/* 
      <Routes>
        <Route
          path={`edit`}
          element={
            <PlaylistModal
              toggle={() => {
                props.updateState();
                gotoPath("");
              }}
            />
          }
        />
      </Routes> 
    */}

      {/* {favoritesModal ? <FavoritesModal toggle={toggleFavoritesModal} /> : null} */}
      {/* {settingsModal ? <SettingsModal toggle={toggleSettingsModal} /> : null} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.user.session,
  env: state.frontend.env,
});
export default connect(mapStateToProps, {})(PlaylistButtons);
