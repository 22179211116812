import _ from "lodash";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import ReactHtmlParser from "react-html-parser";
import nl2br from "react-nl2br";
import { SmartImage } from "components/standart";
import { fileUrl } from "reducers/api";
import TelegramButton from "components/standart/mediaButtons";
import {
  RoundTelegramButton,
  RoundVkButton,
  RoundYandexButton,
  RoundYoutubeButton,
} from "components/standart/mediaButtons";
import { setPlayerPlay, setPlayerVisible } from "actions";

const { LIVESTREAM_URL } = process.env;

export const InfoBlocks = (props) => {
  const {
    showSubscribe = true,
    showLiveStream = true,
    showChannelInfo = true,
    showHowItWorks = true,
    showJoinChannel = true,
  } = props;

  return (
    <div className="text-center">
      {showLiveStream ? <LiveStreamBlock /> : null}
      {/* {showSubscribe ? <SubscribeBlock /> : null} */}
      {showChannelInfo ? <ChannelInfoBlock /> : null}
      {showHowItWorks ? <HowItWorkBlock /> : null}
      {showJoinChannel ? <JoinChannelBlock /> : null}
    </div>
  );
};

export const SubscribeBlock = (cast) => {
  const translate = useTranslate();

  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const { subscribeBlock } = env.settings;

  return subscribeBlock?.title ? (
    <div className="bg-grey p-3 mt-3 mb-4 rounded-xl">
      <div className="large-header  pb-2">{subscribeBlock.title}</div>
      <div className="standart-header content-html pb-3">{nl2br(subscribeBlock.content)}</div>
      <div className="pt-2" />
      <div className="text-center justify-content-center d-flex">
        <RoundTelegramButton
          // button={"Приложение"}
          href={settings.telegramWebApp}
          // postId={cast?.publication?.telegramMessageID}
          className="py-3"
        />
        <div className="mx-4" />
        <RoundYandexButton />
        <div className="mx-4" />
        <RoundVkButton />
      </div>
      <div className="pt-3" />
    </div>
  ) : null;
};

export const ChannelInfoBlock = (cast) => {
  const translate = useTranslate();

  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const { channelInfoBlock } = env.settings;

  return channelInfoBlock?.title ? (
    <div className="bg-grey p-3 mt-3 mb-4 rounded-xl">
      <div className="large-header  pb-2">{channelInfoBlock.title}</div>
      <div className="standart-header content-html pb-3">{nl2br(channelInfoBlock.content)}</div>
      <div className="pt-2" />
      <div className="text-center justify-content-center d-flex">
        <TelegramButton
          button={channelInfoBlock.button}
          href={settings.telegramChannel}
          // postId={cast?.publication?.telegramMessageID}
          className="py-3"
        />
      </div>
      <div className="pt-3" />
    </div>
  ) : null;
};

export const LiveStreamBlock = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const { liveStreamBlock } = env.settings;

  const playerId = "player_live_block";
  useEffect(() => {
    // const player = new window.Playerjs({ id: playerId, file: LIVESTREAM_URL });
  }, []);
  const [livePlaying, setLivePlaying] = useState(false);

  const startPlayerHandler = () => {
    dispatch(setPlayerPlay(false));
    // dispatch(setPlayerVisible(false));
    setLivePlaying(true);
  };

  const pausePlayerHandler = () => {
    setLivePlaying(false);
  };

  const player = useSelector((state) => state.player);
  useEffect(() => {
    if (player.play && livePlaying) {
      setLivePlaying(false);
    }
  }, [player.play]);

  return liveStreamBlock?.title ? (
    <div className="bg-grey p-3 mt-3 mb-4 rounded-xl">
      <div className="large-header  pb-2">{liveStreamBlock.title}</div>

      <div className="standart-header content-html pb-3">{nl2br(liveStreamBlock.content)}</div>

      <div className="text-center justify-content-center d-flex">
        <RoundTelegramButton
          // button={liveStreamBlock.button}
          href={`${settings.telegramChannel}?livestream`}
          className="py-3"
        />
        <div className="mx-3" />
        <RoundVkButton />
      </div>
      <div className="pt-3" />
    </div>
  ) : null;
};

export const LiveStreamShort = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { env } = useSelector((state) => state.frontend);
  const { liveStreamPage, logoFileId } = env.settings;

  const [isReady, setReady] = useState(false);

  const playerId = "player_live_short_block";
  useEffect(() => {
    // const player = new window.Playerjs({ id: playerId, file: LIVESTREAM_URL });
  }, []);
  const [livePlaying, setLivePlaying] = useState(false);

  const removePlaceholderHandler = () => {
    setReady(true);
  };

  const startPlayerHandler = () => {
    dispatch(setPlayerPlay(false));
    // dispatch(setPlayerVisible(false));
    setLivePlaying(true);
  };

  const pausePlayerHandler = () => {
    setLivePlaying(false);
  };

  const player = useSelector((state) => state.player);
  useEffect(() => {
    if (player.play && livePlaying) {
      setLivePlaying(false);
    }
  }, [player.play]);

  return liveStreamPage?.title ? (
    <div className="-bg-grey -p-3 pt-2 mb-4 rounded-xl text-center">
      <div className="large-header">{liveStreamPage.title}</div>

      {_.trim(liveStreamPage.content) ? (
        <div className="standart-header content-html pt-2 pb-4">
          {nl2br(liveStreamPage.content)}
        </div>
      ) : (
        <div className=" pb-4" />
      )}
      {
        //*
        <div
          className="position-relative"
          id={playerId}
          style={{ width: "100%", aspectRatio: "16 / 9" }}
        >
          {!isReady ? (
            <div
              id="placeholder"
              className="position-absolute bg-white d-flex justify-content-center align-items-center"
              style={{ zIndex: 99, width: "102%", height: "102%", top: "-1%", left: "-1%" }}
            >
              <div>
                <i className="fal fa-spinner fa-spin text-secondary" style={{ fontSize: "2rem" }} />
              </div>
            </div>
          ) : null}

          <ReactPlayer
            url={LIVESTREAM_URL}
            playsinline
            width="100%"
            height="100%"
            className="position-absolute rounded-xl overflow-hidden"
            controls={true}
            onDuration={removePlaceholderHandler}
            playing={livePlaying}
            onPlay={startPlayerHandler}
            onPause={pausePlayerHandler}
          />
        </div>
        // */
      }
    </div>
  ) : null;
};

export const HowItWorkBlock = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { howItWorkBlock } = env.settings;

  return howItWorkBlock?.title ? (
    <div className="bg-grey p-3 mt-3 mb-4 rounded-xl">
      <div className="large-header pb-2">{howItWorkBlock.title}</div>
      <div className="standart-header content-html pb-3">{nl2br(howItWorkBlock.content)}</div>
      <div className="pt-2" />
      <Link to={"/about"} className="btn btn-solid">
        <i className="far fa-rocket-launch mr-2" />
        {howItWorkBlock.button}
      </Link>
      <div className="pt-4" />
    </div>
  ) : null;
};

export const JoinChannelBlock = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { joinChannelBlock = {} } = env.settings;

  return joinChannelBlock?.title ? (
    <div className="bg-grey p-3 mt-3 mb-4 rounded-xl">
      <div className="large-header pb-2">{joinChannelBlock.title}</div>
      <div className="standart-header content-html pb-3">{nl2br(joinChannelBlock.content)}</div>
      <div className="pt-2" />
      <Link to={"/join"} className="btn btn-solid">
        <i className="far fa-heart mr-2" />
        {joinChannelBlock.button}
      </Link>
      <div className="pt-4" />
    </div>
  ) : null;
};
