import _ from "lodash";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { SmartImage } from "components/standart";
import nl2br from "react-nl2br";
import { Link, useNavigate } from "react-router-dom";
import { useDemoPlay } from "components/common/player/refs/demo";
import ReactHtmlParser from "react-html-parser";
import SoundMessagePlayButton from "components/standart/soundMessagePlayButton";
import PartnerLogoBlock from "components/standart/partnerLogoBlock";
import { useTranslate } from "react-redux-multilingual";
import { history } from "store";
import { fileUrl } from "reducers/api";

const DemoChannelInfo = (props) => {
  const { channel } = props;
  const { demoPlayRef } = useDemoPlay();
  const navigate = useNavigate();
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { landings = {} } = env;
  const landingData = { ...landings["channels"] };
  const { content = "", isHtml } = { ...landingData["block5"] };

  return channel ? (
    <Fragment>
      <div className="text-center p-3" style={{ position: "relative" }}>
        {/* 
        <div className="d-block text-secondary" style={{ position: "absolute", top: 5, right: 20, fontSize: '2.2rem' }}>
          <i className="fal fa-times" />
        </div>
        */}
        {/*  */}
        <div className="mb-4">
          <PartnerLogoBlock partner={channel} size="large" />
        </div>

        <div className="large-header mt-3">
          <b>{channel.name}</b>
        </div>



        <div className="mt-4 text-truncate">
          <Link
            to={`https://t.me/${channel.alias}`}
            target="_blank"
            className="ml-2 text-black standart-header"
          >
            {`https://t.me/${channel.alias}`}
          </Link>
        </div>


        {content ? (
          <div className="standart-header content-html mt-4">
            {isHtml ? ReactHtmlParser(content) : nl2br(content)}
            {landingData.soundMessage?.fileId ? (
              <SoundMessagePlayButton
                sound={landingData.soundMessage}
                component={<i className="far fa-info-circle ml-2" />}
              />
            ) : null}
          </div>
        ) : null}

        <div className="mt-4">
          <button
            className="btn btn-solid standart-header px-3"
            onClick={() => {
              demoPlayRef.current.playChannel(channel);
              navigate("/");
            }}
          >
            <i className="fa fa-play mr-2" />
            {translate("partner_player")}
          </button>
        </div>
      </div>
    </Fragment>
  ) : null;
};

const mapStateToProps = (state) => ({
  // ...
  player: state.player,
});

export default connect(mapStateToProps, {})(DemoChannelInfo);
