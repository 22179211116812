import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";

import FavoritesIndicator from "./indicator";

const ButtonsMenu = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const gotoPath = (path) => {
    navigate([path].filter(Boolean).join("/"));
  };

  const defaultClass = `col pt-2`;
  const isActiveClass = (url) => (url === location.pathname ? "active-mobile-menu" : "");
  return (
    <div className="bg-grey button-menu">
      <div style={{ height: "1px", backgroundColor: "#000000", opacity: "10%" }}></div>
      <div className="row px-3 pt-1 pb-2">
        <div 
          className={`${defaultClass} ${isActiveClass("/")}`} 
          onClick={() => gotoPath("/")}
          style={{ position: "relative" }}
        >
          <i className="fa fa-play" />
          <div style={{ position: "absolute", top: "0px", left: "50%", marginLeft: "13px" }}>
            <FavoritesIndicator />
          </div>
          <div>
            <small className="text-secondary">{translate("bottom_menu_playlist")}</small>
          </div>
        </div>

        <div
          className={`${defaultClass} ${isActiveClass("/channels")}`}
          onClick={() => gotoPath("/channels")}
        >
          <i className="fa fa-sliders-h" />
          <div>
            <small className="text-secondary">{translate("bottom_menu_channels")}</small>
          </div>
        </div>

        <div
          className={`${defaultClass} ${isActiveClass("/account")}`}
          onClick={() => gotoPath("/account")}
        >
          <i className="fa fa-user" />

          <div>
            <small className="text-secondary">{translate("bottom_menu_account")}</small>
          </div>
        </div>

        {/* 
        <div className={isActiveClass("/account")}>
          <UserLogin />
          <div className="ultra-small">{translate("account")}</div>
        </div>
        */}
        {/* 
        <div className={isActiveClass("/wishlist")}>
          <WishList />
          <div className="ultra-small">{translate("wishlist")}</div>
        </div>
        <div className={isActiveClass("/cart")}>
          <CartContainer />
          <div className="ultra-small">{translate("cart")}</div>
        </div>
        */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, null)(ButtonsMenu);
