import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import nl2br from "react-nl2br";
import ReactHtmlParser from "react-html-parser";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import Col3Container from "components/standart/col3Container";
import { history } from "store";
import { ChannelBlock } from "components/standart/channelBlock";
import { Waypoint } from "react-waypoint";
import { frontendApi } from "reducers/api";
import { LoadingIcon } from "components/standart";

// import { history } from "store";

const { api } = frontendApi.actions;

const ChannelsSection = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const { session } = useSelector((state) => state.user);
  const { env } = useSelector((state) => state.frontend);

  const { landings = {} } = env;
  const landingData = { ...landings.channels };
  const { title = "", content = "", isHtml } = { ...landingData?.intro };

  const [channels, setChannels] = useState([]);
  const [channelsId, setChannelsId] = useState(undefined);
  const [favoritesId, setFavoritesId] = useState([]);
  const [channelsNew, setChannelsNew] = useState({});
  const [categories, setCategories] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [isFavorites, setFavorites] = useState(false);
  const [hasMoreItems, setMoreItems] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isReady, setIsReady] = useState(null);
  const limit = 24;
  const categoriesDivId = "categories_block";

  const favoritesIds = useRef([]);

  const changeTimeout = useRef();
  const delayedChangeLocationSearch = useCallback((...args) => {
    clearTimeout(changeTimeout.current);
    changeTimeout.current = setTimeout(() => {
      changeLocationSearch(...args);
    }, 10);
  }, []);

  // отреагируем на значения учетной записи
  useEffect(() => {
    setFavoritesId(session.account ? session.channelsId : env.playlistDefaults);
  }, [session.account]);

  useEffect(() => {
    favoritesIds.current = favoritesId;
  }, [favoritesId]);

  // в случае изменения location заменим в истории значение на то, которое обнаружилось в адресной строке
  useEffect(() => {
    history.replace([location.pathname, location.search].join(""));
  }, [location]);

  // обработаем адресную строку с небольшой задержкой
  // сделано специально, чтобы не допустить дублирующего старта при обновлении страницы
  useEffect(() => {
    delayedChangeLocationSearch(qs.parse(history.location.search), "history");
  }, [history.location]);

  const changeLocationSearch = useCallback(
    (query, source) => {
      const { categoryId = undefined, search = "", playlist, everything } = query;
      setCurrentCategory(categoryId);
      setSearchName(search);
      setFavorites(!!playlist);
      setTimeout(() => setIsReady(true), 10);
    },
    [favoritesId]
  );

  const checkForNew = (channelsId) => {
    const newChannelsData = { ...channelsNew };
    props.checkChannelsNew(
      { query: { channelId: _.join(channelsId, ",") } },
      {
        onSuccess(result) {
          _.map(result.channelsId, (channelId) => (newChannelsData[channelId] = true));
        },
      }
    );
    setChannelsNew(newChannelsData);
  };

  useEffect(() => {
    props.getCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
          // setTimeout(restoreCategoriesView, 100)
        },
      }
    );
    return () => {
      // history.replace('?')
    };
    // loadChannels(0);
  }, []);

  const restoreCategoriesView = () => {
    const categoriesDiv = document.getElementById(categoriesDivId);
    const [activeElement] = categoriesDiv.getElementsByClassName("active");
    if (activeElement) {
      activeElement.scrollIntoView();
    }
  };

  useEffect(() => {
    if (isFavorites) {
      setCurrentCategory(undefined);
      setChannelsId(_.join(session.account ? session.channelsId : env.playlistDefaults, ","));
    } else {
      setChannelsId(undefined);
    }
  }, [isFavorites]);

  useEffect(() => {
    if (
      isReady &&
      ((channelsId && !currentCategory) ||
        (currentCategory && !channelsId) ||
        (!currentCategory && !channelsId))
    ) {
      // setChannelsNew({});
      loadChannels(0, true);
    } else {
      setChannels([]);
    }
  }, [isReady, channelsId, currentCategory]);

  const setCategory = (category) => {
    emulateNavigate(category._id ? { categoryId: category._id } : {});
  };

  const loadTimeout = useRef();

  const loadChannels = (offset, replace = false) => {
    const query = {
      limit,
      offset,
      name: searchName,
      id: channelsId,
      categoryId: currentCategory,
    };
    // clearTimeout(loadTimeout.current);

    props.getChannels(
      { query },
      {
        onSuccess(body) {
          // const channelsId = _.map(body, (channel) => channel._id);
          // if (session.account) checkForNew(channelsId);
          setChannels(replace ? body : [...channels, ...body]);
          setMoreItems(_.size(body) > 0);
          setLoading(false);
        },
        onRequest() {
          if (replace) setChannels([]);
          setLoading(true);
        },
      }
    );
  };

  const searchTimeout = useRef();
  useEffect(() => {}, [searchName]);

  const searchChannelHandler = ({ target }) => {
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      emulateNavigate({ search: target.value });
    }, 1000);

    setSearchName(target.value);
  };

  const emulateNavigate = useCallback(
    (state) => {
      // const { scrollY } = window;
      // дейстие через history чтобы не допустить скролла экрана
      const search = `?${qs.stringify(state)}`;
      if (history.location.search !== search) {
        setIsReady(false); // отменим готовность, чтобы не допустить загрузки данных на факте изменений
        history.push(`/channels${search}`);
      }
    },
    [history]
  );

  const commonButtonClass =
    "btn btn-rounded btn-sm -font-weight-normal -standart-header border-secondary mr-2 mb-2";
  const activeButtonClass = "btn-dark active";

  return categories ? (
    <section className={props.className}>
      <div className="container py-0">
        <Col3Container>
          <div className="text-center large-header mb-3">{nl2br(title)}</div>
          <div className="text-center standart-header mb-4">
            {isHtml ? ReactHtmlParser(content) : nl2br(content)}
          </div>
          <div
            id={categoriesDivId}
            className="-list-group -list-group-horizontal -text-nowrap -overflow-auto favorites-list position-relative mb-3"
          >
            <span
              className={`${commonButtonClass} ${
                isReady && !isFavorites && !currentCategory ? activeButtonClass : ""
              }`}
              // onClick={() => setCategory({})}
              onClick={() => emulateNavigate({ everything: true })}
            >
              {translate("channels_new")} ({env.channelsCount})
            </span>

            {_.size(favoritesId) ? (
              <span
                className={`${commonButtonClass} ${isFavorites ? activeButtonClass : ""}`}
                onClick={() => emulateNavigate({ playlist: true })}
              >
                <i className="fas fa-check text-success mr-2" />
                {translate("channels_favorites")} ({_.size(favoritesId)})
              </span>
            ) : null}

            {categories.map((category, index) => {
              const btnClass = currentCategory === category._id ? activeButtonClass : "";
              return (
                <span
                  className={`${commonButtonClass} ${currentCategory ? btnClass : ""}`}
                  key={index}
                  onClick={() => setCategory(category)}
                >
                  {category.name} ({category.channelsCount})
                </span>
              );
            })}
          </div>

          <input
            className="form-control rounded mb-4"
            value={searchName}
            placeholder={translate("channels_search")}
            onChange={searchChannelHandler}
          />

          <div className="row mt-1">
            {_.map(channels, (channel, index) => {
              return (
                <ChannelBlock
                  channel={channel}
                  className="col-4 col-lg-3 "
                  hasNew={channelsNew[channel._id]}
                  key={index}
                />
              );
            })}

            {isLoading && (
              <div className="col-12 text-center py-3">
                <LoadingIcon />
              </div>
            )}

            <Waypoint
              bottomOffset={"-1px"}
              // debug={true}
              onEnter={() => {
                if (isReady && hasMoreItems && !isLoading) {
                  loadChannels(channels.length);
                }
              }}
            />
          </div>

          {isFavorites ? null : <RequestChannelMessage />}
        </Col3Container>
      </div>
    </section>
  ) : null;
};

const RequestChannelMessage = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const translate = useTranslate();
  const { telegram } = env.settings;
  return (
    <div className="text-center">
      <div>{translate("request_channel_text")}</div>
      <a href={telegram} className="btn btn-solid px-3 my-4 standart-header" target="_blank">
        <i className="fab fa-telegram-plane mr-2" />
        {translate("request_channel_button")}
      </a>
    </div>
  );
};

export default connect(null, {
  getChannels: api.get("/channels"),
  checkChannelsNew: api.get("/channels/checkNew"),
  getCategories: api.get("/channels/categories"),
})(ChannelsSection);
