import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import path from "path";
import TelegramEmbed from "react-telegram-embed";
import { frontendApi } from "reducers/api";
import { LoadingIcon, useWindowSize } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import Col3Container from "components/standart/col3Container";

const { api: apiFrontend } = frontendApi.actions;

const ReadCastMessages = (props) => {
  const { cast, navi } = props;
  const translate = useTranslate();
  const [showScrollTop, setShowScrollTop] = useState(null);
  const [height, setHeight] = useState(80);
  const [containerHeight, setCointainerHeight] = useState(80);

  const [messages, setMessages] = useState([]);
  const timeoutRef = useRef(null);
  const searchRef = useRef();
  const containerRef = useRef();

  const size = useWindowSize();
  const containerId = "read_container";

  useEffect(() => {
    if (containerRef.current) {
      const setHeightValue = () => {
        const { top } = containerRef.current.getBoundingClientRect();
        setCointainerHeight(size.height - Math.round(top) - 10 - navi.bottomSpaceHeight);
      };
      setTimeout(setHeightValue, 500);
    }
  }, [size, containerRef.current]);

  useEffect(() => {
    props.getCastMessages(
      { query: { castId: cast._id } },
      {
        onSuccess(body) {
          setMessages(body);
        },
      }
    );

    props.modalRef.current.addEventListener("scroll", handleScroll);
    timeoutRef.current = setTimeout(handleScroll, 1000);
    return () => {
      clearTimeout(timeoutRef.current);
      // props.modalRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollTopHandler = () => {
    props.modalRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = (event) => {
    let number = props.modalRef.current.scrollTop;
    setShowScrollTop(number > searchRef.current.offsetTop);
  };

  return _.size(messages) ? (
    <div className="position-relative" ref={searchRef}>
      <Col3Container>
        {/* <div className="text-center large-header mb-4">{translate("casts_read_version")}</div> */}
        <div
          className="overflow-auto"
          style={{ height: `${containerHeight}px` }}
          id={containerId}
          ref={containerRef}
        >
          {_.map(messages, (message, index) => {
            // const post = path.join(message.channel.alias, String(message.ident));
            // const html = `
            //   <script async src="https://telegram.org/js/telegram-widget.js?${index}" data-telegram-post="${post}" data-width="100%"></script>
            // `;
            const post = path.join(message.channel.alias, String(message.ident));
            return (
              <div key={index} style={{ zIndex: "1" }}>
                <TelegramEmbed src={`https://t.me/${post}`} />
              </div>
            );
          })}
          {/* <div style={{ marginBottom: `${navi.bottomSpaceHeight}px` }}></div> */}
        </div>
      </Col3Container>
      
    </div>
  ) : (
    <div className="text-center p-3">
      <LoadingIcon />
    </div>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, {
  getCastMessages: apiFrontend.get("/casts/messages"),
})(ReadCastMessages);
