/** автономная форма авторизации
 * может быть вставлена в другие связанные компоненты
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { authApi, userApi } from "reducers/api";
import { useTranslate } from "react-redux-multilingual";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoginForm from "./form";
const { session } = userApi.actions;
const { auth } = authApi.actions;

let Auth = (props) => {
  const translate = useTranslate();

  const confirmHandler = (req, res) => {
    props.validateCode(req, { ...res });
  };

  return (
    <>
      {/* <div className="py-2">{translate("auth_phone")}</div> */}
      {/* <div className="py-2">{translate("auth_title")}</div> */}

      <LoginForm type="email" requestHandler={props.requestCode} confirmHandler={confirmHandler} />
    </>
  );
};

Auth = connect(null, {
  ...auth.actions,
  ...session.actions,
})(Auth);
export default Auth;

/*
export const AuthModal = (props) => {
  const translate = useTranslate();
  const { toggle } = props;
  return (
    <Modal centered isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>{translate("auth")}</ModalHeader>
      <ModalBody>
        <Auth />
      </ModalBody>
    </Modal>
  );
};
*/
