import apiClass from "./init";
const env = new apiClass();

env.actions = {
  // общие данные для пользователей
  systemEnv: env.get("/env", {
    success(state, body) {
      return { ...state, ...body };
    },
  }),
};

export default env;
