import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PartnerLogo from "components/standart/partnerLogo";
import { fileUrl } from "reducers/api";

const PartnerLogoBlock = (props) => {
  const { env, partner, size = "small" } = props;
  const { settings = {} } = env;

  return partner ? (
    <div className={`partner-logos ${props.className}`}>
      <span className={`partner ${size}`}>
        <PartnerLogo {...{ partner }} type={props.type} style={props.style} />
      </span>
      {props.children}
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  env: state.frontend.env,
});

export default connect(mapStateToProps, {})(PartnerLogoBlock);
