import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { useNavigate } from "react-router-dom";

// import Name from "./name";
// import Phone from "./phone";
import Email from "./email";

import { authApi, userApi } from "reducers/api";
const { auth } = authApi.actions;
const { session } = userApi.actions;

const Settings = (props) => {
  const { sessionStore } = props;
  const translate = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const safeLogout = () => {
    // безопасный выход подразумевает закрытие модалки
    // и последующий выход
    navigate("/", { replace: true });
    dispatch(
      session.actions.logout(
        {},
        {
          onSuccess() {
            props.logout(
              {},
              {
                onSuccess() {
                  // document.location.href = "/";
                },
              }
            );
          },
        }
      )
    );
    // navigate("/");
  };

  return (
    <div>
      <div className="mb-4">
        {/* <Email /> */}
        {sessionStore.account.profile?.name}
      </div>
      <span className="btn btn-solid mb-3 standart-header py-3" onClick={safeLogout}>
        {translate("logout")}
      </span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.user.session,
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  ...auth.actions,
})(Settings);
