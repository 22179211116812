import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { iconFontSize } from "constants/index";

// import SearchCastsChannels from "components/standart/searchCastsChannels";
import CastsSearchModal from "views/casts/search";

const SearchModal = (props) => {
  const [searchModal, setSearchModal] = useState(false);
  const modalRef = useRef(null);
  const toggleModal = () => setSearchModal(!searchModal);
  // */
  const style = { fontSize: iconFontSize };
  return (
    <>
      <div className="mt-1" onClick={toggleModal}>
        <a
          className="text-black -standart-header text-decoration-none relative"
          style={{ cursor: "pointer", ...style }}
        >
          <i className="far fa-search" />
        </a>
      </div>

      <Modal
        isOpen={searchModal}
        centered
        toggle={toggleModal}
        wrapClassName={"fullscreen-modal"}
        innerRef={modalRef}
      >
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody>
          {/* <SearchCastsChannels modalRef={modalRef} toggleModal={toggleModal} /> */}
          <CastsSearchModal modalRef={modalRef} closeHandler={toggleModal} />
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.user.session,
});

export default connect(mapStateToProps, {})(SearchModal);
