import _ from "lodash";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { frontendApi } from "reducers/api";
import { setPlaylistListenedMessages } from "actions";
import { useTranslate } from "react-redux-multilingual";

import { IndicatorButton, CommonPlayerButton } from "./index";
import PlayerMessage from "./message";
const { api } = frontendApi.actions;

const PlayerListVisual = (props) => {
  const { cast, channels, messages, player, collapseLimit } = props;
  const [showCollapse, setShowCollapse] = useState(!collapseLimit);
  const toggleCollapse = () => setShowCollapse(!showCollapse);
  const translate = useTranslate();

  useEffect(() => {
    const messagesId = _.map(messages, (message) => message._id);
    messagesId.length &&
      props.getStats(
        { body: { messagesId } },
        {
          onSuccess(body) {
            props.setPlaylistListenedMessages(body.listensId);
          },
        }
      );
  }, [messages]);

  return (
    <>
      {_.map(messages, (message, index) => {
        return showCollapse || index < collapseLimit ? (
          <CommonPlayerButton disabled={player.disabled} {...{ message }} key={message._id}>
            <PlayerMessage {...{ message, channels, cast, index }}>
              <IndicatorButton {...{ message }} />
            </PlayerMessage>
          </CommonPlayerButton>
        ) : null;
      })}

      {collapseLimit && messages.length > collapseLimit ? (
        <div className="text-secondary pt-2" onClick={toggleCollapse} style={{ cursor: "pointer" }}>
          <small>
            {translate(showCollapse ? "collapse_messages" : "show_all_messages", {
              count: messages.length,
            })}
            <i className={`fa ${showCollapse ? "fa-angle-up" : "fa-angle-down"} ml-2`} />
          </small>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  player: state.player,
});

export default connect(mapStateToProps, {
  getStats: api.post("/stats/check"),
  setPlaylistListenedMessages,
})(PlayerListVisual);
