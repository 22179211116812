export const iconFontSize = "1.4rem";

export const PLAYER_TYPE_ADVERT = "advertCompany";
export const PLAYER_TYPE_SOUND = "sound";
export const PLAYER_TYPE_MESSAGE = "message";

export const helmetContext = {};

export const DATA_COUNTRY_HEADER = "data-country-id";
export const DATA_COUNTRY_CATEGORY_HEADER = "data-category-id";
