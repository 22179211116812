import React from "react";
import { connect } from "react-redux";

const Col3Container = (props) => {
  return (
    <div className="row justify-content-center">
      <div className={`col-lg-6 col-12 ${props.className}`}>{props.children}</div>
    </div>
  );
};

export default connect(null, {})(Col3Container);
