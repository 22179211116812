import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import nl2br from "react-nl2br";
import ReactHtmlParser from "react-html-parser";
import SoundMessagePlayButton from "./soundMessagePlayButton";
import { SmartImage } from "components/standart";
import { fileUrl } from "reducers/api";
import Col3Container from "./col3Container";

export const IntroSection = (props) => {
  const { env } = useSelector((state) => state.frontend);

  const { landings = {} } = env;
  const landingData = { ...landings[props.type] };
  const { title = "", content = "", fileId, isHtml } = { ...landingData?.intro };
  return title || content ? (
    <section className={`bg-white pb-${props.paddingBottom} pt-${props.paddingTop}`}>
      <div className="container py-1">
        <Col3Container>
          <div className="text-center">
            <div className="large-header pb-2">{nl2br(title)}</div>
            <div className="standart-header mb-4">
              {isHtml ? ReactHtmlParser(content) : nl2br(content)}
            </div>

            {fileId ? (
              <div className="mb-3">
                <SmartImage src={fileUrl(fileId)} width="100%" />
              </div>
            ) : null}

            {landingData.soundMessage?.fileId ? (
              <SoundMessagePlayButton sound={landingData.soundMessage} />
            ) : null}
            <div className="mb-2" />
          </div>
        </Col3Container>
      </div>
    </section>
  ) : null;
};

export const BlockSection = (props) => {
  const { env } = useSelector((state) => state.frontend);
  const { landings = {} } = env;
  const landingData = { ...landings[props.type] };
  const { title = "", content = "", isHtml } = { ...landingData[props.blockName] };
  return title || content ? (
    <section
      className={`${props.bgColor || "bg-white"} pb-${props.paddingBottom || 0} pt-${
        props.paddingTop
      }`}
    >
      <div className={`mb-${props.marginTop}`} />
      <div className="container py-1">
        <Col3Container>
          <div className={`text-center ${props.containerClass}`}>
            <div className="large-header pb-2">{title}</div>
            <div className="standart-header content-html">
              {isHtml ? ReactHtmlParser(content) : nl2br(content)}
            </div>
            {props.children}
          </div>
        </Col3Container>
      </div>
      <div className={`mb-${props.marginBottom}`} />
    </section>
  ) : null;
};
