import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";

export const TelegramBackButtonOverload = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [backButton, setBackButton] = useState(false);

  const locationHistory = useRef([]);

  const backButtonHandler = useCallback(() => {
    const currentLocation = locationHistory.current.pop();
    const lastLocation = locationHistory.current.pop();
    const path = `${lastLocation.pathname}${lastLocation.search}${lastLocation.hash}`;
    navigate(path);
  }, [locationHistory]);

  useEffect(() => {
    if (backButton) {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.BackButton.onClick(backButtonHandler);
    } else {
      window.Telegram.WebApp.BackButton.hide();
      window.Telegram.WebApp.BackButton.offClick(backButtonHandler);
    }
  }, [backButton]);

  useEffect(() => {
    // если работаем в контексте телеграм канала
    if (window.Telegram.WebApp) {
      const lastLocation = locationHistory.current.pop();
      // если последняя локация была найдена, то сравним её с той, что пришла
      if (lastLocation) {
        // вернем ее на место
        locationHistory.current.push(lastLocation);
        // если адрес на совпадает с новой, то поместим в стек новое значение
        if (
          lastLocation.pathname !== location.pathname ||
          lastLocation.search !== location.search ||
          lastLocation.hash !== location.hash
        ) {
          locationHistory.current.push(location);
        }
      } else {
        // иначе просто добавим значение в список
        locationHistory.current.push(location);
      }
      //
      setBackButton(locationHistory.current.length > 1);
    }
  }, [location]);

  return (
    <>
      {false && backButton ? (
        <button className="btn btn-sm btn-info" onClick={backButtonHandler}>
          back
        </button>
      ) : null}
    </>
  );
};
