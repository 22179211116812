import React from "react";
import noImg from "assets/images/no-photo.png";
import { fileUrl } from "reducers/api";
import { SmartImage } from 'components/standart';

const PartnerLogo = (props) => {
  const { partner } = props;
  return (
    <SmartImage
      className="rounded-circle border border-1"
      src={partner.fileId ? fileUrl(partner.fileId, props.type || "preview.jpg") : noImg}
      {...props}
    />
  );
};

export default PartnerLogo;
