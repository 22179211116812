import React from "react";
import { useTranslate } from "react-redux-multilingual";
import { LogoText } from "components/standart";
import { Link } from "react-router-dom";

function Logo(props) {
  const translate = useTranslate();
  return (
    <>
      <Link
        to={`/`}
        className="d-block text-center mb-1"
        style={{ textDecoration: "none", color: "black" }}
      >
        {/* <img src={`/logo.png`} alt="" className="img-fluid" style={{ height: "40px" }} /> */}
        <LogoText />
      </Link>
      <div className="clearfix" />
    </>
  );
}

export default Logo;
