import { useEffect, useRef } from "react";
import NoSleep from "nosleep.js";

export const useNoSleep = (props) => {
  //
  const noSleepRef = useRef(undefined);

  useEffect(() => {
    noSleepRef.current = new NoSleep();
  }, []);

  return { noSleepRef };
};
