import _ from "lodash";
import { useCookieConsent } from "@use-cookie-consent/core";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop } from "components/standart";
import { setNavi } from "actions";
import { IntroSection } from "components/standart/LandingSections";
import ExtraBottomSection from "components/standart/extraBottomSection";
import { fileUrl } from "reducers/api";

import DemoChannelModal from "./channels/demo_modal";
import PlaylistSection from "./playlist/section";
import CastsSection from "./casts";

const { GOOGLE_CERTIFICATE_NUMBER } = process.env;

const Index = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);


  const { consent, acceptAllCookies } = useCookieConsent({
    defaultConsent: {
      thirdParty: true,
      firstParty: true,
      persistent: true,
      session: true,
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    },
    consentCookieAttributes: { sameSite: "None" },
  });

  const { pathname, search, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          // window.scrollTo(0, 400);
          element.scrollIntoView();
        }
      }, 500);
    }
  }, [pathname, hash, key]); // do this on route change

  const { meta = {} } = env;

  useEffect(() => {
    scrollTop();
    props.setNavi("/");

    // acceptAllCookies();

    const startInstagramVisual = () => {
      try {
        window.instgrm.Embeds.process();
      } catch (e) {
        setTimeout(startInstagramVisual, 500);
      }
    };
    setTimeout(startInstagramVisual, 500);
  }, []);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.index || {};
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
        <meta property="og:type" content="website" />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <CastsSection className="m-0 p-0" />

      {/* <ExtraBottomSection /> */}

      {/* <IntroSection type="index" /> */}
      {/* <InfoSection type="index" /> */}
      {/* <DemoChannelModal /> */}
      {/* <PlaylistSection /> */}
      {/* <HowToSection type="index" /> */}
      {/* <HowToFaqSection /> */}
      {/* 
      <CommonBlockSection type="index">
        <>
          <section className="bg-white">
            <div className="container">
              <PageButtons simulate={true} />
            </div>
          </section>

          <MissionSection type="index" />
        </>
      </CommonBlockSection>
       */}

      {/* <FastFeedback /> */}
    </>
  );
};

export default connect(null, {
  setNavi,
})(Index);
