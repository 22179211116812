/** автономная форма авторизации
 * может быть вставлена в другие связанные компоненты
 */
import _ from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";

import { Alert } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import { authApi, userApi } from "reducers/api";
import { useAuthSockets } from "./authSockets";

const { auth } = authApi.actions;

const AuthTelegramWait = (props) => {
  const { socketsRef } = useAuthSockets({ onconnect: () => submitHandler() });
  const { session } = useSelector((state) => state.user);

  const submitHandler = () => {
    const webAppData = { ...window.Telegram.WebApp.initDataUnsafe };
    const { user = {} } = webAppData;
    // сначала проверим на небезопасных данных, что у нас есть рабочий контекст сессии и всего остального
    // если в контексте не определена сессия, или текущий логин не равен значению user.id
    if (!session.login || String(session.login.value) !== String(user.id)) {
      // то вызовем процедуру авторизацию по коду из приложения
      const code = window.Telegram.WebApp.initData;
      if (code) {
        props.telegramAuth(
          { body: { code } },
          {
            onSuccess() {
              setTimeout(() => props.closeHandler(), 1000);
            },
            onFailure() {
              props.closeHandler();
            },
            onRequest() {},
          }
        );
      } else {
        // иначе закроем текущий слой
        props.closeHandler();
      }
    } else {
      // иначе закроем текущий слой
      props.closeHandler();
    }
  };

  return <div></div>;
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  telegramAuth: auth.post("/telegram/app"),
})(AuthTelegramWait);
