import { combineReducers } from "redux";
import { IntlReducer as Intl } from "react-redux-multilingual";
// import { routerReducer } from "react-router-redux";
import { withReduxStateSync } from "redux-state-sync";
// Import custom components
import whatsapp from "./whatsapp";
import navi from "./navigation";
import locales from "./locales";
import player from "./player";
import playlist from "./playlist";
import { frontendApiReducer as frontend } from "./api/frontend";
import { userApiReducer as user } from "./api/user";
import { authApiReducer as auth } from "./api/auth";
import { statsApiReducer as stats } from "./api/stats";
import { dataApiReducer as data } from "./api/data";

const rootReducer = combineReducers({
  //(asyncReducers) =>
  whatsapp,
  navi,
  // ...asyncReducers,
  // routerReducer,
  frontend,
  user,
  auth,
  player,
  playlist,
  Intl,
  locales,
  stats,
  data,
});

export default withReduxStateSync(rootReducer);

export const middlewares = [];
