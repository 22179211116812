import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, Alert } from "reactstrap";

import { frontendApi, userApi } from "reducers/api";
import { withTranslate } from "react-redux-multilingual";
import { DATA_COUNTRY_CATEGORY_HEADER } from "constants";

const { api: frontend } = frontendApi.actions;
const { api: users, session } = userApi.actions;

const CountryCategoriesModal = (props) => {
  const { translate, sessionStore } = props;

  // const [email, setEmail] = useState(null);

  useEffect(() => {
    // setEmail(sessionStore.email);
  }, [sessionStore]);

  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);

  const errorState = useState(false);
  const [error, setError] = errorState;

  const loadingState = useState(false);
  const [isLoading, setLoading] = loadingState;

  const successState = useState(false);
  const [success, setSuccess] = successState;

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (openModal) {
      props.getCategories(
        {},
        {
          onSuccess(data) {
            setCategories(data);
            setLoading(false);
          },
          onRequest() {
            setLoading(true);
          },
        }
      );
      // тут получение данных
    }
  }, [openModal]);

  const selectCategoryHandler = (categoryId) => {
    //
    if (sessionStore.account) {
      props.saveCountryCategory(
        { body: { categoryId } },
        {
          onSuccess() {
            localStorage.setItem(DATA_COUNTRY_CATEGORY_HEADER, categoryId);
            toggleModal();
            props.successHandler();
          },
        }
      );
    } else {
      localStorage.setItem(DATA_COUNTRY_CATEGORY_HEADER, categoryId);
      toggleModal();
      props.successHandler();
    }
  };

  return (
    <>
      <div className="text-center standart-header font-weight-normal">
        <small className="btn btn-sm  text-secondary font-weight-normal" onClick={toggleModal}>
          {translate("edit_region")}
          <i className="fa fa-angle-down ml-1" />
        </small>
      </div>

      {openModal ? (
        <Modal
          centered
          isOpen={openModal}
          wrapClassName="fullscreen-modal"
          size="md"
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <div className="row">
              {categories.map((category, index) => {
                const currentCategoryId = localStorage.getItem(DATA_COUNTRY_CATEGORY_HEADER);
                const isActive = currentCategoryId
                  ? category._id === currentCategoryId
                  : category.countryDefault;
                return (
                  <div
                    className="col-12 text-center large-header mb-2"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => selectCategoryHandler(category._id)}
                  >
                    {category.name}
                    {isActive ? <i className="fal fa-check ml-2" /> : null}
                  </div>
                );
              })}
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

const mapeStateToProps = (state) => ({
  sessionStore: state.user.session,
});

export default connect(mapeStateToProps, {
  ...session.actions,
  getCategories: frontend.get("/geo/categories"),
  saveCountryCategory: users.post("/account/countryCategory"),
})(withTranslate(CountryCategoriesModal));
