import _ from "lodash";
import { SET_LOCALE_COUNTRY, SET_LOCALE_DATA } from "constants/ActionTypes";

export default (state = { data: {}, country: {} }, action) => {
  switch (action.type) {
    // установить текущую локаль
    case SET_LOCALE_DATA:
      return {
        ...state,
        data: action.localeData,
      };
    // установить текущую страну
    case SET_LOCALE_COUNTRY:
      return {
        ...state,
        country: action.country,
      };
    default:
  }
  return state;
};
