import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop } from "components/standart";
import { setNavi } from "actions";
import { BlockSection, IntroSection } from "components/standart/LandingSections";
import ExtraBottomSection from "components/standart/extraBottomSection";
import { fileUrl } from "reducers/api";
import ChannelsSection from "./section";

const ChannelsList = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);

  const { meta = {}, settings = {} } = env;

  useEffect(() => {
    scrollTop("channels/index.jsx");
    props.setNavi("/");
  }, []);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.channels || {};
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <ChannelsSection />
      <ExtraBottomSection />
    </>
  );
};

export default connect(null, {
  setNavi,
})(ChannelsList);
