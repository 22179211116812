import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Popover, PopoverBody } from "reactstrap";
import { iconFontSize } from "constants/index";

export const contentId = "content";
export const friendId = "friend";
export const celebrityId = "celebrity";

export const ShareButton = (props) => {
  const [copied, setCopied] = useState(false);
  const tooltipTimeout = useRef();
  useEffect(() => {
    if (copied) {
      tooltipTimeout.current = setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const id = `share_${props.id}`;
  
  const translate = useTranslate();

  const copyLink = useCallback(
    (event) => {
      event.preventDefault();
    },
    [id]
  );
  return (
    <>
      {window.Telegram.WebApp.initData &&
      window.Telegram.WebView.initParams.tgWebAppPlatform !== "web" ? (
        <a
          href={props.url}
          id={id}
          className="-d-block -text-black"
          target="_blank"
          title={translate("share")}
          data-id="share-daily"
          style={{ textDecoration: "none" }}
        >
          {props.children}
        </a>
      ) : (
        <>
          <CopyToClipboard text={props.url} onCopy={() => setCopied(true)}>
            <a
              href={props.url}
              id={id}
              className="-d-block -text-black"
              target="_blank"
              title={translate("share")}
              data-id="share-daily"
              onClick={copyLink}
              style={{ textDecoration: "none" }}
            >
              {props.children}
            </a>
          </CopyToClipboard>
          {copied && (
            <Popover fade={true} placement="top" isOpen={copied} target={id}>
              <PopoverBody>{translate("url_copied")}</PopoverBody>
            </Popover>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  locales: state.locales,
});

export default connect(mapStateToProps, {})(ShareButton);

export const ShareCastButton = (props) => {
  const { cast, special } = props;
  const style = { fontSize: iconFontSize };

  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;
  const translate = useTranslate();

  // сформируем ссылку в зависимости от контекста происходящего
  let url = document.location.href;
  // если есть телеграм приложение
  if (window.Telegram.WebApp.initData) {
    const args = cast ? `castId-${cast._id}` : `specialId-${special._id}`;
    // то ссылка равна ссылке на приложение
    url = `${settings.telegramWebApp}?startapp=${args}`;
    // а если это не веб-версия телеграма, то ссылка равна "поделиться через телегу"
    if (window.Telegram.WebView.initParams.tgWebAppPlatform !== "web") {
      url = `https://t.me/share/url?url=${url}`;
    }
  }

  return (
    <ShareButton url={url} castId={cast ? cast._id : special._id}>
      <div className="d-flex flex-column text-black">
        <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
          <div className=" w-100 h-100">
            <img
              src={"/grey.png"}
              className="rounded-circle"
              // style={{ border: "2px solid black" }}
            />
          </div>
          <div
            className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
            style={{ ...style }}
          >
            <i className={`far fa-share`} />
          </div>
        </div>
        <div className="text-secondary mt-1">
          <small>{translate("share")}</small>
        </div>
      </div>
    </ShareButton>
  );
};
