import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { frontendApi } from "reducers/api";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "query-string";
import { Waypoint } from "react-waypoint";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, useWindowSize } from "components/standart";

import Col3Container from "components/standart/col3Container";

import CastData, { CastInfoSmall } from "./cast";

const { api: apiFrontend } = frontendApi.actions;

const SpecialsIndex = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();

  const [currentSpecialId, setCurrentSpecialId] = useState(null);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentCast, setCurrentCast] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [castsList, setCastsList] = useState([]);
  const [castsLoading, setCastsLoading] = useState();

  const { session } = useSelector((state) => state.user);

  const { env } = useSelector((state) => state.frontend);

  const { meta = {}, settings = {} } = env;

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.specials || {};

  // в случае изменения location заменим в истории значение на то, которое обнаружилось в адресной строке
  useEffect(() => {
    // history.replace([location.pathname, location.search].join(""));
    const { specialId, categoryId } = qs.parse(location.search);

    setIsReady(true);
    // отработать логику чуть позже
    setCurrentSpecialId(specialId);
    setCurrentCategoryId(categoryId);
  }, [location.search]);

  useEffect(() => {
    const query = {};
    if (currentSpecialId) {
      query.specialId = currentSpecialId;
      props.getSpecialsInfo(
        { query },
        {
          onSuccess(body) {
            setCurrentCast(body);
          },
          onRequest() {
            setCurrentCast({});
          },
          onFailure() {
            setCurrentCast(null);
          },
        }
      );
    }
  }, [currentSpecialId]);

  const loadCastsList = useCallback(
    (offset) => {
      const query = { offset, categoryId: currentCategoryId };

      props.getSpecialsList(
        { query },
        {
          onSuccess(body) {
            setCastsList(body);

            setCastsLoading(false);
          },
          onRequest() {
            setCastsLoading(true);
          },
          onFailure() {
            setCastsLoading(false);
          },
        }
      );
    },
    [castsList, currentCategoryId]
  );

  useEffect(() => {
    if (!currentSpecialId && isReady) {
      setCurrentCast({});
      setCastsList([]);
      loadCastsList(0);
    }
  }, [
    // currentCategoryId, currentCategory,
    currentSpecialId,
    isReady,
  ]);

  const selectCastId = (specialId) => {
    navigate(specialId ? `?specialId=${specialId}` : "?");
  };

  const size = useWindowSize();

  return (
    <section className="bg-white py-0 my-0">
      <div className="container py-0">
        <Col3Container>
          {castsLoading ? (
            <div className="text-center p-3">
              <LoadingIcon />
            </div>
          ) : (
            <div>
              {currentSpecialId ? (
                <>
                  {currentCast ? (
                    <CastData cast={currentCast} />
                  ) : (
                    <div className="large-header text-center p-5">404</div>
                  )}
                </>
              ) : (
                <>
                  <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                    <meta property="og:title" content={title} />
                    <meta name="og:description" content={description} />
                    <meta name="og:keywords" content={keywords} />
                  </Helmet>

                  <div className="text-center large-header pb-3">{translate("specials_casts")}</div>

                  <div className="pb-4">
                    <div className="row px-2">
                      {_.map(castsList, (cast, index) => {
                        return (
                          <div className="col-6 col-lg-4 px-2" key={index}>
                            <div
                              style={{ cursor: "pointer" }}
                              className="pb-3"
                              onClick={() => selectCastId(cast._id)}
                            >
                              <CastInfoSmall cast={cast} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Col3Container>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, {
  getSpecialsList: apiFrontend.get("/specials"),
  getSpecialsInfo: apiFrontend.get("/specials/info"),
})(SpecialsIndex);
