/**
 * генератор запросов к СloClo API
 * возвращает класс, который собирает структуру для генерации actions, reducer и middleware
 *
 */
import _ from "lodash";
import { combineReducers } from "redux";
import { push } from "react-router-redux";

// здесь будут уже сконфигурированные редюсеры
import { SUCCESS, REQUEST, FAILURE } from "./init";
import customerReducers from "./reducers";

import { safeClearAuthSession } from "../special";

export default customerReducers;

// типовой редюсер для API
export function reducer(state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case SUCCESS:
      return { ...state, body: payload.body };

    case FAILURE:
      return {
        ...state,
        error: _.get(payload, "response.message") || payload.statusText,
      };

    case REQUEST:
      return { ...state, timestamp: new Date() };

    default:
      return state;
  }
}

export const userApiReducer = combineReducers({
  api: reducer,
  ...customerReducers.reducers,
});
// export const { frontendApi }Reducer = reducer;

export const userApiMiddleware =
  (store) =>
  (next) =>
  (action = { type: "" }) => {
    const result = next(action);
    const { payload } = action;

    // если по какой-то причине вернулся 401 или 303, то полностью закроем сессию и удалим токены
    if ([401, 303].includes(_.get(payload, "status"))) {
      safeClearAuthSession(store);
      return false;
    }

    // если по какой-то причине вернулся 303, то полностью обновим страницу
    // это означает, что умерла кука сессии, или сломался отпечаток клиента
    if (303 === _.get(payload, "status")) {
    }

    if (_.get(payload, "message") === "Failed to fetch") {
      // console.log("enable lock screen");
    }

    return result;
  };

export const middlewares = [userApiMiddleware, ...customerReducers.middlewares];
