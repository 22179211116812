import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { accessToken } from "reducers/api/token";
import { useDispatch } from "react-redux";
import { SocketsController } from "reducers/api/sockets";
import { userApi } from "reducers/api";

const { session } = userApi.actions;

export const useAuthSockets = (params) => {
  //
  const dispatch = useDispatch();

  const onmessage = function (msg) {
    const authToken = String(msg.data);
    localStorage.setItem(accessToken, authToken);
    dispatch(session.actions.getUser());
  };

  const socketsRef = useRef(new SocketsController("/auth", { onmessage, ...params }, params));

  const connectSocket = () => {
    socketsRef.current.connect();
  };
  const destorySocket = () => {
    socketsRef.current.safeClose();
  };

  useEffect(() => {
    // console.log("init component and socket subscribe");
    try {
      connectSocket();
      return () => {
        destorySocket();
      };
    } catch (e) {
      console.error(e);
    }
  }, []);

  return { socketsRef };
};
