import _ from "lodash";
import apiClass from "./init";
import { accessToken } from "../token";

const session = new apiClass();

session.actions = {
  getUser: session.get("/account", {
    success: (state, result) => {
      const checkFields = ["account", "logins"];
      // хитрая процедура для получения надежного признака, что базовая информация о пользователе обновилась
      // в дальнейшем позволяет опираться на признак  __isUpdated для принятия решений в useEffect
      const __isUpdated = _.isEqual(
        _.pick(state, checkFields),
        _.pick(result, checkFields),
      )
        ? state.__isUpdated
        : Date.now();
      return { ...state, ...result, __isUpdated };
    },
  }),

  // saveFavoriteAddresses: session.post("/account/addresses/favorites"),
  logout: session.get("/", {
    request: () => {
      return { account: null };
    },
  }),
};

export default session;
