import _ from "lodash";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { SmartImage } from "components/standart";
import { useTranslate } from "react-redux-multilingual";
import PartnerLogoBlock from "components/standart/partnerLogoBlock";

const PlayerMessage = (props) => {
  const { message, advert, channels, cast, index } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (message) {
      setData({
        id: message.id,
        date: cast.channelId ? message.date : null,
        number: cast.channelId ? null : index + 1,
        fileId: channels[message.channelId]?.fileId,
        title: channels[message.channelId]?.name,
        text: message.text,
      });
    } else if (advert) {
      setData({
        id: advert.id,
        image: advert.image,
        title: advert.title,
        text: advert.content,
      });
    } else if (props.data) {
      setData(props.data);
    }
  }, [props]);

  return data ? (
    <Fragment>
      {props.debug && JSON.stringify(data)}
      {
        //*
        <PlainMessage {...data} key={data.id} {...props}>
          {props.children}
        </PlainMessage>
        //*/
      }
    </Fragment>
  ) : null;
};

export const PlainMessage = (props) => {
  const translate = useTranslate();
  const today = moment().startOf("day").toDate();
  const dateShortFormat = translate("dateShortFormat");
  const timeShortFormat = translate("timeShortFormat");

  const [dateFormat, setDateFormat] = useState(false);

  useEffect(() => {
    props.date && setDateFormat(getTimeFormat(props.date));
  }, [props]);

  const getTimeFormat = (date) => {
    date = new Date(date);
    if (date > today) {
      return timeShortFormat;
    } else {
      const diff = moment(today).diff(date, "day");
      if (diff < 7) {
        const dayNum = date.getDay();
        return translate(`day_${dayNum}`);
      } else {
        return dateShortFormat;
      }
    }
  };

  return (
    <div className="d-flex mb-2">
      <div className="mr-3 my-auto position-relative">
        {props.image ? (
          <div className={`partner-logos`} onClick={props.onClick}>
            <span className={`partner small`}>
              <SmartImage src={props.image} className="rounded-circle" />
            </span>
          </div>
        ) : (
          <PartnerLogoBlock partner={{ fileId: props.fileId }} />
        )}
        {props.showLinkIcon ? (
          <span
            className="position-absolute text-secondary"
            style={{ zIndex: 10, bottom: -3, right: -3, cursor: "pointer" }}
            onClick={props.onClick}
          >
            <i
              className="fab fa-telegram rounded-circle"
              style={{
                color: "#0088cc",
                backgroundColor: "white",
                border: "solid 2px white",
                fontSize: "1.2rem",
              }}
            />
          </span>
        ) : null}
      </div>
      <div className="mr-auto -text-truncate" onClick={props.onClick}>
        <div className="text-left player-text-1">
          <b>{props.title}</b>
        </div>
        <div className="-text-nowrap">
          <div
            className="mr-auto text-left player-text-2 text-secondary"
            style={{ lineHeight: 1.2 }}
            // style={{ lineClamp: 2, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            <small>{props.text}</small>
          </div>
        </div>
      </div>
      <div className="mx-1"></div>
      <div className="ml-auto">
        {props.date ? (
          <div className="text-secondary text-right">
            <small>
              {dateFormat ? (
                moment(props.date).format(dateFormat)
              ) : (
                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
              )}
            </small>
          </div>
        ) : null}
        {props.number ? (
          <div className="text-secondary text-right">
            <small>{props.number}</small>
          </div>
        ) : null}
        <div className="mt-1">{props.children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  // ...
  player: state.player,
});

export default connect(mapStateToProps, {})(PlayerMessage);
