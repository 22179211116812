import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { setPlayerSound, setPlayerPlay, setPlayerCastId, setPlayerVisible, setPlayerMessage } from "actions";

const SoundMessagePlayButton = (props) => {
  const { sound, player } = props;
  const dispatch = useDispatch();

  const [isSoundPlaying, setIsSoundPlaying] = useState(false);

  useEffect(() => {
    setIsSoundPlaying(player.sound?.fileId === sound.fileId);
  }, [player.sound]);

  const clickHandler = () => {
    if (isSoundPlaying) {
      dispatch(setPlayerPlay(!player.play));
    } else {
      dispatch(setPlayerMessage(null));
      dispatch(setPlayerSound({ ...sound }));
      dispatch(setPlayerPlay(true));
      dispatch(setPlayerCastId(null));
      dispatch(setPlayerVisible(true));
    }
  };

  return props.component 
    ? <span onClick={clickHandler}>{props.component}</span>
    : (
      <button className={`btn btn-solid py-3 standart-header ${props.className}`} onClick={clickHandler}>
        <i className={`${isSoundPlaying && player.play ? "fa fa-pause" : "fas fa-play"} mr-2`} />
        {sound.button}
      </button>
    );
};

const mapStateToProps = (state) => ({
  player: state.player,
});

export default connect(mapStateToProps, {})(SoundMessagePlayButton);
