import _ from "lodash";
import { useEffect, useRef, useCallback, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { frontendApi } from "reducers/api";
import {
  setPlayerMessages,
  setPlayerMessage,
  setPlayerCurrentId,
  setPlayerVisible,
  setPlayerSound,
  setPlayerPlay,
  setPlayerChannels,
  setPlayerShowChannel,
  togglePlayerPlay,
} from "actions";

const { api } = frontendApi.actions;

const getChannelMessages = api.get("/channels/messages");
const getChannelInfo = api.get("/channels/info");
export const useDemoPlay = (props) => {
  //

  class DemoPlay {
    constructor() {}

    playChannel(channel) {
      demoPlayChannel(channel);
    }

    loadAndPlayChannel(alias) {
      loadChannelThenPlay(alias);
    }

    hideDemoChannel() {
      hideDemoChannelHandler();
    }
  }

  const demoPlayRef = useRef(new DemoPlay());
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);

  const playerChannelsId = useRef([]);
  const playerShowChannel = useRef(null);

  useEffect(() => {
    playerShowChannel.current = player.showChannel;
  }, [player.showChannel]);

  useEffect(() => {
    playerChannelsId.current = player.channelsId;
  }, [player.channelsId]);

  const demoPlayChannel = useCallback(
    (channel, autoPlay = true) => {
      const channelId = channel._id;

      const isCurrentChannelPlaying = _.isEqual(playerChannelsId.current, [channelId]);

      if (isCurrentChannelPlaying) {
        dispatch(togglePlayerPlay());
      } else {
        const query = { channelId, beforeDate: new Date(), limit: 5 };
        dispatch(
          getChannelMessages(
            { query },
            {
              onSuccess(messages) {
                messages = _.reverse(messages); // развернем сообщения в обратном порядке времени
                dispatch(setPlayerChannels([channel]));
                dispatch(setPlayerMessages(messages));
                dispatch(setPlayerVisible(true));
                dispatch(setPlayerSound(messages[0]));
                /*
                закомментированный кусок, который надо разобрать, чтобы проигрывать приветственный звук канала
                if (channel.introSoundMessage?.fileId) {
                  dispatch(setPlayerSound(channel.introSoundMessage));
                  dispatch(setPlayerMessage(messages[0]));
                  dispatch(setPlayerCurrentId(messages[0]._id));
                } else {
                  dispatch(setPlayerSound(messages[0]));
                }
                */
                setTimeout(() => dispatch(setPlayerPlay(!!autoPlay)), 100);
                // dispatch(setPlayerSound(channel.introSoundMessage?.fileId ? channel.introSoundMessage : messages[0]));
              },
            }
          )
        );
      }

      // setScrollTop(props.modalRef.current.scrollTop);
      // setChannelData(channel);
    },
    [playerChannelsId]
  );

  const loadChannelThenPlay = useCallback((alias) => {
    dispatch(
      getChannelInfo(
        { query: { alias } },
        {
          onSuccess(channel) {
            dispatch(setPlayerShowChannel(channel));
            demoPlayChannel(channel, false);

            // console.log(channel);
          },
        }
      )
    );
  }, []);

  const hideDemoChannelHandler = useCallback(() => {
    if (playerShowChannel.current) {
      dispatch(setPlayerShowChannel(null));
    }
  }, [playerShowChannel]);

  useEffect(() => {
    // demoPlayRef.current = new DemoPlay();
  }, []);

  return { demoPlayRef };
};
