import _ from "lodash";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerLogoBlock from "components/standart/partnerLogoBlock";
// import { history } from "store";
import { useNavigate } from "react-router-dom";
import AuthModal from "views/auth/modal";
import { useTranslate } from "react-redux-multilingual";
import { backgroundCover, SmartImage } from "components/standart";
import { fileUrl } from "reducers/api";
import CastsFavoriteButton from "components/standart/cast-favorite-button";

import { iconFontSize } from "constants";
import { Popover, PopoverBody } from "reactstrap";
import { userApi } from "reducers/api";
import { setPlayerShowChannel } from "actions";

const { api } = userApi.actions;

export const ChannelBlock = (props) => {
  const { channel } = props;
  // const { landings = {} } = env;
  const navigate = useNavigate();
  // const landingData = { ...landings[props.type] };
  const className = "text-black text-decoration-none";

  return (
    <div
      className={`${props.className} text-center px-1`}
      onClick={() => (props.onClick ? props.onClick() : navigate(`/${channel.alias}`))}
    >
      <div className="">
        <PartnerLogoBlock
          partner={channel}
          size={props.size || "medium"}
          className="position-relative d-inline-block"
        >
          <div className="position-absolute" style={{ top: -5, right: -5, zIndex: 99 }}>
            {props.hideFavorite ? null : (
              <FavoriteButton
                className="btn-outline"
                {...channel}
                channel={channel}
                size="micro"
                style={{ marginTop: "7px" }}
                fontSize={iconFontSize}
              />
            )}
          </div>
          <div className="position-absolute" style={{ bottom: 0, right: 0, zIndex: 99 }}>
            {false && props.hasNew ? (
              <i className="fas fa-circle text-danger" style={{ fontSize: "5px" }} />
            ) : null}
          </div>
        </PartnerLogoBlock>
      </div>
      <div className="p-2  overflow-hidden -text-secondary" style={{ lineHeight: "1.2" }}>
        <small>{channel.name}</small>
      </div>
    </div>
  );
};

export const ChannelBlockSquare = (props) => {
  const { channel, isCategory, showFavorite } = props;
  const navigate = useNavigate();

  return (
    <div
      style={{ cursor: "pointer" }}
      className={`${props.className} text-center`}
      onClick={() => (props.onClick ? props.onClick() : navigate(`/${channel.alias}`))}
    >
      <div className="position-relative">
        {isCategory ? (
          <div className="fixed-corner-left rounded-xl-one" style={{ zIndex: 99 }}>
            <div className="text">
              <small>
                <i className="fal fa-thumbtack" />
              </small>
            </div>
          </div>
        ) : null}

        <div className="text-center position-relative rounded rounded-xl border border-1">
          <div
            className="block-squared"
            // style={backgroundCover(fileUrl(channel.fileId, "image.jpg"))}
          ></div>
          <div className="d-flex w-100 h-100 position-absolute" style={{ left: 0, top: 0 }}>
            <SmartImage
              src={fileUrl(channel.fileId, "image.jpg")}
              style={{ objectFit: "cover", overflow: "hidden" }}
              className="rounded rounded-xl w-100"
            />
          </div>
        </div>
      </div>
      <div className="-position-absolute w-100 -cover-text-block d-flex text-black rounded rounded-xl mt-2">
        {showFavorite ? (
          <div className="position-absolute" style={{ top: 5, right: 13 }}>
            <CastsFavoriteButton
              categoryId={isCategory ? channel._id : undefined}
              channelId={isCategory ? undefined : channel._id}
              style={{
                width: "25px",
                height: "25px",
                // backgroundColor: "#eb0029", color: "white"
              }}
              className="btn btn-sm p-0 rounded-circle bg-grey"
              iconClassName={"fas"}
              isButton={true}
            />
          </div>
        ) : null}
        <div className="mt-auto w-100">
          {/* <div className="standart-header font-weight-bold text-truncate mb-1">{channel.name}</div> */}
          <small className="-d-block font-weight-bold -text-truncate player-text-2 mb-1">
            {channel.name}
          </small>
          {/* 
          {channel.castsSchedules ? (
            <div className="pb-1 text-secondary">
              <small>
                <i className="fal fa-sync-alt mr-2" />
                {channel.castsSchedules}
              </small>
            </div>
          ) : null}
          */}
          <div className="pt-2" />
        </div>
      </div>
    </div>
  );
};

export const ChannelMicroBlock = (props) => {
  const { channel } = props;
  // const { landings = {} } = env;
  const navigate = useNavigate();
  // const landingData = { ...landings[props.type] };
  const className = "text-black text-decoration-none";

  return (
    <div
      className={`${props.className}`}
      onClick={() => (props.onClick ? props.onClick() : navigate(`/${channel.alias}`))}
    >
      <div className="d-flex">
        <div className="my-auto text-nowrap text-left">{channel.name}</div>
        <div className="ml-1">
          <PartnerLogoBlock
            partner={channel}
            size={props.size || "medium"}
            className="position-relative d-inline-block"
          />
        </div>
      </div>
    </div>
  );
};

const addFavorite = api.post("/account/favorites");
const deleteFavorite = api.post("/account/favorites/delete");

export const FavoriteButton = (props) => {
  const { channel } = props;
  const { session } = useSelector((state) => state.user);
  const { env } = useSelector((state) => state.frontend);
  const dispatch = useDispatch();
  const [id, setId] = useState(Date.now());
  const [isClickAuth, setClickAuth] = useState(null);
  const [nextFavoriteId, setNextFavoriteId] = useState(null);
  const [favoritesList, setFavoritesList] = useState([]);
  const [channelInFavorite, setChannelIsFavorite] = useState(undefined);
  const [result, setResult] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    if (channel?._id) {
      setChannelIsFavorite(favoritesList.includes(channel._id));
    }
  }, [favoritesList, channel]);

  // снимаем регистрацию по клику при появлении сессии
  useEffect(() => {
    if (session.account) {
      setClickAuth(false);
      if (nextFavoriteId) {
        favoritesHandler({ _id: nextFavoriteId })();
      }
      setFavoritesList(session?.channelsId || []);
    } else {
      setFavoritesList(env?.playlistDefaults || []);
    }
  }, [session]);

  const tooltipTimeout = useRef();
  useEffect(() => {
    if (result) {
      tooltipTimeout.current = setTimeout(() => setResult(null), 5000);
    }
  }, [result]);

  const favoritesHandler = (channel) => (e) => {
    if (e) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    if (isLoading) return false;
    const channelId = channel._id;

    // dispatch(setPlayerShowChannel(null));

    if (!session.account) {
      setClickAuth(true);
      setNextFavoriteId(channelId);
      return false;
    }
    setNextFavoriteId(null); // отметим, что следющий раз в избранное добавлять ничего не надо
    // если канал уже в избранном, то удалим его оттуда
    if (channelInFavorite) {
      dispatch(
        deleteFavorite(
          { body: { channelId } },
          {
            onSuccess(body) {
              dispatch(
                userApi.actions.session.actions.getUser(
                  {},
                  {
                    onSuccess() {
                      setResult("playlist_removed");
                      setLoading(false);
                    },
                  }
                )
              );
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    } else {
      dispatch(
        addFavorite(
          { body: { channelId } },
          {
            onSuccess(body) {
              dispatch(
                userApi.actions.session.actions.getUser(
                  {},
                  {
                    onSuccess() {
                      setResult("playlist_added");
                      setLoading(false);
                    },
                  }
                )
              );
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  const iconId = `fav_${id}_${channel._id}`;
  return (
    <Fragment>
      {props.isButton ? (
        <button
          disabled={props.disabled}
          className={`btn ${props.className}`}
          onClick={favoritesHandler(channel)}
          id={iconId}
        >
          {isLoading || _.isUndefined(channelInFavorite) ? (
            <i
              className={`fas icon fa-spinner-third fa-spin -mr-2  ${props.iconClassName}`}
              //
              // style={props.style}
            />
          ) : (
            <i
              className={`icon ${props.iconClassName} ${
                channelInFavorite ? "fas fa-check text-success" : "fas fa-plus"
              } -mr-2`}
              // style={props.style}
            />
          )}
          {props.showText ? (
            <>{channelInFavorite ? translate("favorites_edit") : translate("favorites_add")}</>
          ) : null}
        </button>
      ) : (
        <span
          className={`partner ${props.size} d-block`}
          style={{ fontSize: props.fontSize }}
          onClick={favoritesHandler(channel)}
          id={iconId}
        >
          <img src={"/grey.png"} className="rounded-circle -border -border-1" />
          {isLoading || _.isUndefined(channelInFavorite) ? (
            <i
              className="fas icon fa-spinner-third fa-spin"
              //
              style={props.style}
            />
          ) : (
            <i
              className={`icon ${channelInFavorite ? "fas fa-check text-success" : "fas fa-plus"}`}
              style={props.style}
            />
          )}
        </span>
      )}

      {result ? (
        <Popover fade={true} placement="top" isOpen={true} target={iconId}>
          <PopoverBody>{translate(result)}</PopoverBody>
        </Popover>
      ) : null}

      {isClickAuth && <AuthModal toggleModal={() => setClickAuth(false)} />}
    </Fragment>
  );
};
