import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import qs from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop } from "components/standart";
import { Waypoint } from "react-waypoint";
import { LoadingIcon } from "components/standart";
import { frontendApi, fileUrl } from "reducers/api";

import CastData, { CastInfo, CastInfoSmall, ShowCastDuration } from "../casts/cast";

const { api } = frontendApi.actions;

const CastsListData = (props) => {
  const { query, defaultImageId } = props;
  const translate = useTranslate();

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [castId, setCastId] = useState(null);
  const [castInfo, setCastInfo] = useState(null);
  const [castsList, setCastsList] = useState([]);
  const [castsLoading, setCastsLoading] = useState(null);
  const [castsMore, setCastsMore] = useState(null);

  /*
  useEffect(() => {
    const { castId } = qs.parse(location.search);
    setCastId(castId);
  }, [location.search]);

  useEffect(() => {
    if (castId) {
      setCastsList([]);
      props.getCastsInfo(
        { query: { castId } },
        {
          onSuccess(body) {
            setCastInfo(body);
          },
          onFailure() {
            selectCastId(null);
          },
        }
      );
    } else {
      setCastInfo(null);
    }
  }, [castId]);
  */

  useEffect(() => {
    // scrollTop();
  }, []);

  const loadCastsList = (offset) => {
    props.getCastsList(
      { query: { ...query, offset } },
      {
        onSuccess(body) {
          //
          setCastsMore(body.length > 0);
          setCastsList([...(offset > 0 ? castsList : []), ...body]);
          setCastsLoading(false);
        },
        onRequest() {
          setCastsLoading(true);
          //
        },
        onFailure() {
          //
          setCastsLoading(false);
        },
      }
    );
  };

  const selectCastId = (castId) => {
    navigate(castId ? `?castId=${castId}` : "?");
  };

  useEffect(() => {
    scrollTop("casts-data.jsx");
    loadCastsList(0);
  }, [query]);

  return !castId ? (
    <div className="my-auto text-center">
      <div className="text-center large-header pb-3">{translate("casts_archive")}</div>

      <div className="pb-4">
        <div className="row px-2">
          {_.map(castsList, (cast, index) => {
            return (
              <div className={`col-6 col-md-4 px-2`} key={index}>
                <div
                  style={{ cursor: "pointer" }}
                  className="pb-3"
                  onClick={() => selectCastId(cast._id)}
                >
                  {index >= 0 ? (
                    <CastInfoSmall
                      cast={cast}
                      defaultImageId={defaultImageId || cast.channel?.fileId}
                    />
                  ) : (
                    <CastInfo cast={cast} defaultImageId={defaultImageId || cast.channel?.fileId} />
                  )}
                </div>
              </div>
            );
          })}
        </div>

        <Waypoint
          bottomOffset={"-1px"}
          onEnter={() => {
            if (castsMore && !castsLoading) {
              loadCastsList(castsList.length);
            }
          }}
        />

        {castsLoading ? (
          <div className="text-center p-5">
            <LoadingIcon />
          </div>
        ) : null}
      </div>
      {/* 
      <div className="pt-1 pb-4">
        <ChannelPlayer {...{ channel: channelData }} />
      </div>
      */}
    </div>
  ) : (
    <>
      {castInfo ? (
        <CastData cast={castInfo} defaultImageId={defaultImageId} />
      ) : (
        <div className="text-center p-5">
          <LoadingIcon />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  // ...
});

export default connect(mapStateToProps, {
  getCastsList: api.get("/casts"),
  getCastsInfo: api.get("/casts/info"),
})(CastsListData);
