// использование плеера https://playerjs.com
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { castFirstSound } from "components/standart";
import { fileUrl, frontendApi, dataApi } from "reducers/api";
import {
  resetPlayer,
  setPlayerAdvert,
  setPlayerDisabled,
  setPlayerMessage,
  setPlayerMessages,
  setPlayerPlay,
  setPlayerProgress,
  setPlayerSound,
  addPlaylistListenedMessages,
  setPlayerShowChannel,
  setPlayerFavoritePlaying,
  setPlayerCastId,
  setPlayerChannels,
  setPlayerCurrentId,
} from "actions";

const { api: data } = dataApi.actions;
const { api: frontend } = frontendApi.actions;

const sendMessageStats = data.post("/stats/message");
const sendAdvertStats = data.post("/stats/advert");
const sendSpecialsStats = data.post("/stats/specials");
const getAdvertIntros = frontend.get("/intros");
const getNextCast = frontend.get("/casts/next");
const getCastMessages = frontend.get("/casts/messages");

const IS_MESSAGE = "message";
const IS_ADVERT = "advert";
const IS_INTRO = "intro";
const IS_JINGLE = "jingle";
const IS_SOUND = "sound";

/*
ключевой задачей плеера является возможность вставить в промежуток между исполнениями треков
основного плейлиста рекламу и звуковые сообщения

проигрывание звуков и рекламы может иметь общую механику, и в общем случае должно быть сопряжено
со следующей логикой:

- вызов функции, которая останавливает текущее воспроизведение плеера, очищает текущий плейлист плеера
при этом затрагиается только значение playerJs, а не player

- в плеере формируется новый список: джингл, приветствие (для рекламы), и само сообщение. При этом важно 
синхронизировать их с состоянием player.sound, чтобы оно корректно отобразилось в самом плеере

- по факту окончания проигрывания звука (на тригере finish) проверяется, есть ли еще список сообщений 
в `player.messages`, и затем воспроизводится первое сообщение в списке  (но для этого следует переделать логику
`player.messages` таким образом, чтобы у меня всегда оставался только "хвост с головой", а всё что уже проигралось
или не будет проигрываться откидывалось из списка)


*/

class PlayerJSContainer {
  playerJs;
  playerBgJs;

  playerSpeed;
  jingleMp3;
  finalSound;
  bgSounds;

  currentPlayingId;
  currentTimeNumber;

  currentId;
  lastChannelId;

  playlistMessages;
  playlistChannels;

  // текущее объявление
  currentAdvert;
  advertsIntros;

  // текущий звук
  currentSound;

  // currentBgSound;
  currentBgSoundIndex;
  currentBgSoundLoaded;

  lastCommands = [];

  // ручки для управления данные в reducer-ах
  resetAdvertHandler; // сбросить объявление
  resetPlayerHandler; // сбросить плеер
  sendStatsHandler; // отправить статистику
  playerDisabledHandler; // управление активностью преела
  setCurrentIdHandler; // установить текущий ID сообщения
  playerCurrentCastIdHandler; // установить текущий ID выпускач
  setSoundHandler; // установить текущий звук
  onProgressHandler; // установить прогресс сообщения
  onPlayHandler; // включить воспроизведение
  onPauseHandler; // включит ьпаузу
  clearPlayerHandler; // очистить плеер
  lastMessagePlayedHandler; // процедура обработки последнего сообщения в плейлисте
  logHandler; // логировать действия

  lastCheckFinishId;
  checkFinishTimeout;
  keepLogTimer;

  playlistModified; // признак обновленного плейлиста

  constructor(jingleMp3, finalSound, bgSounds) {
    //
    this.jingleMp3 = jingleMp3;
    this.finalSound = finalSound;
    this.bgSounds = bgSounds;
    this.currentBgSoundIndex = -1;
    this.playlistMessages = [];
    this.advertsIntros = [];
  }

  /*
  // пока закомментируем старую логику
  getBgSound() {
    const hour = new Date().getHours();
    const [currentBgSound] = _.filter(this.bgSounds, (data) => {
      // если это с вечера на ночь
      if (data.hoursFrom > data.hoursTill) {
        return hour >= data.hoursFrom || hour <= data.hoursTill;
      } else {
        // если в течение дня
        return hour >= data.hoursFrom && hour <= data.hoursTill;
      }
    });

    if (currentBgSound) {
      return currentBgSound; // fileUrl(currentBgSound.fileId, "file.mp3");
    }
  }
  // */

  shuffleBgSounds() {
    this.bgSounds = _.shuffle(this.bgSounds);
  }

  nextBgSound() {
    //
    if (this.currentBgSoundIndex >= this.bgSounds.length - 1) {
      this.currentBgSoundIndex = 0;
    } else {
      this.currentBgSoundIndex += 1;
    }
    const nextSound = this.bgSounds[this.currentBgSoundIndex];
    return nextSound;
  }

  setBgSoundLoaded() {
    // если фоновый звук не загружен, то отметим его загруженным, и выполним накопленные команды
    if (!this.currentBgSoundLoaded) {
      this.currentBgSoundLoaded = true;
      if (this.lastCommands.length) {
        this.lastCommands.forEach((commands) => this.command(...commands));
        this.lastCommands = [];
      }
    }
  }

  setOnlyCurrentId(id) {
    // console.log ('do set current id', id);
    this.currentId = id;
  }

  setCurrentId(id) {
    this.setOnlyCurrentId(id);
    this.setCurrentIdHandler(this.currentId);
  }

  init(id) {
    // сбросим плеер, если тот уже был создан
    if (this.playerJs) {
      this.destroy();
    }
    // const file2 = this.getBgSound();
    const playerOpts = { id, log: 0 };
    this.playerJs = new window.Playerjs(playerOpts);

    const playerElem = document.getElementById(id);
    playerElem.addEventListener("play", () => {
      // контрольный перехват запуска воспроизведения из плеера на экране блокировки
      this.onPlayHandler();
    });

    playerElem.addEventListener("pause", (...args) => {
      // контрольный перехват паузы на экране блокировки
      // console.log(`playerElem.addEventListener("pause")`, args);
      this.onPauseHandler();
      this.playerDisabledHandler(false); // плеер активен, если стал на паузу
    });

    // в начале проигрывания сообщения
    playerElem.addEventListener("new", () => {
      const playingId = String(this.playerJs.api("playlist_id"));
      if (!playingId || this.currentPlayingId === playingId) return;
      this.currentPlayingId = playingId;
      const [id, type, subType] = _.split(playingId, "_");

      // запускается, когда начинается джингл
      if (subType === IS_MESSAGE) {
        this.setCurrentId(id);
        // извлечем channelId для текущего сообщения по его id
        const [{ channelId }] = _.filter(this.playlistMessages, (msg) => msg.id === playingId);
        // console.log ('new channelId', channelId);
        this.lastChannelId = channelId; // установим последний проигрываемый канал
        this.playerDisabledHandler(false); // плеер активен, если играется сообщение
      } else if ([IS_ADVERT, IS_SOUND].includes(subType)) {
        // this.playerDisabledHandler(true);
      } else {
        // если тип не принадлежит никакому из выше перечисленных, то считаем это идентификатором канала
      }

      this.command("unmute");
      this.command("volume", 1);

      this.currentTimeNumber = 0;
      // специально пропустим отправку данных о начале прослушивания сообщения, потому что оно было отправлено при прослушивании джингла
      if (type !== IS_MESSAGE) {
        this.onProgressHandler(0);
        // this.command("speed", "1.0"); // для всех объектов кроме сообщений скорость обычная
      } else {
      }

      // this.command("speed", (_.toNumber(this.playerSpeed) || 1).toFixed(2)); // для сообщений - скорость из настроек
      this.sendStatsHandler(playingId, 0); // укажем отметим, что запустилось проигрывание трека
      this.logHandler({ event: "new playing", playingId });
    });

    playerElem.addEventListener("time", () => {
      const playingId = String(this.playerJs.api("playlist_id"));
      const [id, type] = _.split(playingId, "_");
      const time = this.playerJs.api("time");
      const duration = this.playerJs.api("duration");
      const progress = duration > 0 ? time / duration : 0;
      // this.logHandler({ event: "time progress", playingId, time, progress });
      this.currentTimeNumber += 1;
      // каждый 15-й шаг будем отправлять статистику, пока она меньше 1
      const skipProgressStep = progress < 1 && this.currentTimeNumber % 15;
      // визуализация прогресса и отправка статистики возможна только на сообщениях, рекламе и звуках
      if ([IS_MESSAGE, IS_ADVERT, IS_SOUND].includes(type) && progress > 0) {
        this.onProgressHandler(progress); // изменим значение на индикаторе
        !skipProgressStep && this.sendStatsHandler(playingId, progress); // отправим значение в статистику
      }

      // если сообщение доигралось до конца отправим
      if (progress === 1) {
        this.logHandler({ event: "progress complete", playingId });
        this.unifiedCheckFinishedSound(playingId);
      }
    });

    // если внезапно выключился звук
    playerElem.addEventListener("mute", () => {
      this.command("unmute");
    });

    // здесь следует проверить, что действительно было доиграны все возможные сообщения, и если это не так
    // то следует вызвать процедуру доигрывания, а если все ок, то следует корректно погасить плеер
    // обработка на завершении всего плейлиста
    playerElem.addEventListener("finish", (...args) => {
      const playingId = String(this.playerJs.api("playlist_id"));
      // console.log("control finish playing", playingId, new Date().getTime());
      this.logHandler({ event: "finish playing", playingId });
      this.delayedCheckFinish(playingId);
    });

    playerElem.addEventListener("end", () => {
      const playingId = String(this.playerJs.api("playlist_id"));
      // console.log("control end playing", playingId, new Date().getTime());
      this.logHandler({ event: "end playing", playingId });

      this.delayedCheckFinish(playingId);
      //
    });

    playerElem.addEventListener("stop", () => {
      const playingId = String(this.playerJs.api("playlist_id"));
      this.logHandler({ event: "stop playing", playingId });

      if (playingId) {
        // this.delayedCheckFinish(playingId);
      }
    });

    playerElem.addEventListener("error", () => {
      const playingId = String(this.playerJs.api("playlist_id"));
      this.logHandler({ event: "error playing", playingId });
    });

    playerElem.addEventListener("loaderror", () => {
      const playingId = String(this.playerJs.api("playlist_id"));
      this.logHandler({ event: "loaderror playing", playingId });
    });

    playerElem.addEventListener("userplay", () => {
      this.logHandler({ event: "userplay" });
      this.onPlayHandler();
    });

    playerElem.addEventListener("userpause", () => {
      this.logHandler({ event: "userpause" });
      this.onPauseHandler();
    });

    /*
    playerElem.addEventListener("error", () => {
      this.onPauseHandler();
    });
    playerElem.addEventListener("loaderror", () => {
      this.onPauseHandler();
    });
    */
  }

  delayedCheckFinish(playingId) {
    clearTimeout(this.checkFinishTimeout);
    this.checkFinishTimeout = setTimeout(
      (function (t, id) {
        return () => {
          t.unifiedCheckFinishedSound(id);
        };
      })(this, playingId),
      70
    );
  }

  unifiedCheckFinishedSound(playingId) {
    if (this.lastCheckFinishId === playingId) return;
    // защита от дубликатов вызовов
    this.lastCheckFinishId = playingId;

    const [id, type] = _.split(playingId, "_");
    this.logHandler({ method: "unifiedCheckFinishedSound", playingId });
    // если было проиграно сообщение, и следующей стоит проигрывание рекламы - запустим его
    if (type === IS_MESSAGE && this.currentAdvert) {
      // то запустим рекламу
      // console.log("on new track advert is", this.currentAdvert);
      this.startAdvertPlay();
    } else if (type === IS_ADVERT) {
      // если было проиграно сообщение, то
      this.completeAdvertPlay();
      // если завершился плейлист с объявлениями
      // то восстановим сообщения, и запустим
    } else if (type === IS_MESSAGE && !this.isLastMessage(id) && this.playlistModified) {
      this.restoreMessagesPlay(true); // восстановим проигрывание сообщений и начнем со следующего
    } else if (type === IS_MESSAGE && this.isLastMessage(id)) {
      // console.log("last message played");
      // то следует проверить
      // если завершился плейлист с сообщениями
      // то следует сбросить плейлист
      // this.setCurrentIdHandler(null);
      // this.clearPlayerHandler();
      // и проиграть финальное сообщение
      this.startFinalMessage();
    } else if (type === IS_SOUND) {
      // если завершился плейлист со звуком
      this.completeSoundMessage();
    } else {
      // в любом другом случае (джингл или интро) - запустим следующий трек
      // this.setCurrentIdHandler(null);
      this.command("next");
    }
  }

  // проверка, что указанное сообщение последнее в списке
  isLastMessage(messageId) {
    const lastMessageId = `${messageId}_${IS_MESSAGE}`;
    const currentIndex = _.findIndex(
      this.playlistMessages,
      ({ id }) => `${id}`.search(lastMessageId) >= 0
    );
    return !this.playlistMessages[currentIndex + 1];
  }

  // запуск объявления
  startAdvertPlay() {
    const advertPlaylist = [];
    const { advertCompanyId, title, image, mp3 } = this.currentAdvert;
    // добавляем джингл
    const jingle = {
      id: `${advertCompanyId}_${IS_JINGLE}_${IS_ADVERT}`,
      title,
      poster: image,
      file: this.jingleMp3,
    };
    advertPlaylist.push(jingle);
    // если было найдено случайное вступление, то добавим его
    const [randomIntro] = _.shuffle(this.advertsIntros);
    if (randomIntro) {
      const intro = {
        id: `${advertCompanyId}_${IS_INTRO}_${IS_ADVERT}`,
        title,
        poster: image,
        file: fileUrl(randomIntro.fileId, "file.mp3"),
      };
      advertPlaylist.push(intro);
    }
    const advert = {
      id: `${advertCompanyId}_${IS_ADVERT}_${IS_ADVERT}`,
      title,
      poster: image,
      file: mp3,
    };
    advertPlaylist.push(advert);
    this.command("playlist", [...advertPlaylist]); // загрузим новый плейлист
    this.command("play", `id:${advertCompanyId}_${IS_JINGLE}_${IS_ADVERT}`); // запустим его проигрывание с джингла
    // console.log("start play advert", advertPlaylist);
    this.setSoundHandler(this.currentAdvert);
  }

  // завершить проигрывание рекламы
  completeAdvertPlay() {
    this.resetAdvertHandler(); // сбросим текущее объявление в player
    this.restoreMessagesPlay(true); // восстановим проигрывание сообщений
  }

  startFinalMessage() {
    this.setOnlyCurrentId(null);
    this.clearPlayerHandler();
    this.setSoundHandler(this.finalSound);
  }

  playSoundMessage(data) {
    const soundMessagePlaylist = [];
    const { id, title, image, mp3 } = data;
    // добавляем джингл
    const jingle = {
      id: `${id}_${IS_JINGLE}_${IS_SOUND}`,
      title,
      poster: image,
      file: this.jingleMp3,
    };
    soundMessagePlaylist.push(jingle);
    // добавляем проигрывание звука
    const sound = {
      id: `${id}_${IS_SOUND}_${IS_SOUND}`,
      title,
      poster: image,
      file: mp3,
    };
    soundMessagePlaylist.push(sound);
    this.command("playlist", [...soundMessagePlaylist]); // загрузим новый плейлист
    this.command("play", `id:${id}_${IS_JINGLE}_${IS_SOUND}`); // запустим его проигрывание с джингла
  }

  // завершить проигрывание звукового сообщения
  completeSoundMessage() {
    // console.log("do complete sound message", this.currentId, this.playlistMessages);
    this.restoreMessagesPlay(this.currentSound.playNext); // восстановим проигрывание с последнего
  }

  /** восстановить проигрывание сообщений */
  restoreMessagesPlay(playNext) {
    // найдем последнее проигранное сообщение согласно сохраненному значению currentId
    const lastMessageId = `${this.currentId}_${IS_MESSAGE}`;
    this.logHandler({ method: "restoreMessagesPlay", playNext, lastMessageId });
    // найдем сообщение по совпадению id и типу
    const currentIndex = _.findIndex(
      this.playlistMessages,
      ({ id }) => `${id}`.search(lastMessageId) >= 0
    );
    // console.log ('currentIndex is', currentIndex);
    // найдем следующее сообщение в плейлисте

    const nextMessage = this.playlistMessages[currentIndex + (playNext ? 1 : 0)];
    // console.log("restoreMessagesPlay", nextMessage);
    // если оно существует, то запустим его
    if (nextMessage) {
      this.setOnlyCurrentId(null);
      this.playerCurrentCastIdHandler(nextMessage.castId || nextMessage.speciaId);
      const [_id] = _.split(nextMessage.id, "_");
      const { channelId } = nextMessage;
      this.play({ _id, channelId });
    } else if (playNext) {
      // если следующее сообщения не было найдено, то проиграем финальный звук
      this.startFinalMessage();
    } else {
      // console.log("thats all");
      this.lastMessagePlayedHandler();
    }
  }

  // сгенерировать вступление для канала
  getChannelIntro(messageId, channel) {
    const channelId = channel._id;
    const introMp3 = fileUrl(channel.soundFileId, "file.mp3");
    const title = channel.name;
    const poster = fileUrl(channel.fileId, "image.jpg");
    return {
      id: `${messageId}_${IS_INTRO}_${channelId}`,
      title,
      channelId,
      poster,
      file: introMp3,
    };
  }

  messageById(id) {
    const [message] = this.playlistMessages.filter((message) => message.id.search(id) === 0);
    return message;
  }

  // установить плейлист
  playlist(messages, channels) {
    const files = [];
    let lastChannelId;
    this.playlistChannels = channels;
    _.map(messages, (message) => {
      const { channelId, specialId, castId } = message;
      const channel = channels[channelId];
      const title = channel.name;
      const poster = fileUrl(channel.fileId, "image.jpg");
      // добавляем джингл
      const jingle = {
        id: `${message._id}_${IS_JINGLE}_${IS_MESSAGE}`,
        title,
        channelId,
        specialId,
        castId,
        poster,
        file: this.jingleMp3,
      };
      files.push(jingle);
      // если очередное id в канале отличалось от предыдущего, и количество каналов больше 1
      // то добавим вступление канала (если оно есть)
      if (lastChannelId !== channelId && _.size(channels) > 0 && channel.soundFileId) {
        lastChannelId = channelId;
        const channelIntro = this.getChannelIntro(message._id, channel);
        files.push({ ...channelIntro, castId, specialId });
      }
      // добавляем непосредственно само сообщение
      const file = {
        id: `${message._id}_${IS_MESSAGE}_${channelId}`,
        title,
        channelId,
        specialId,
        castId,
        poster,
        file: message.mp3,
      };
      files.push(file);
    });
    this.playlistMessages = [...files]; // сохраним в текущем экземпляре класса данные о сообщениях в плейлисте

    this.playlistModified = true; // отметим, что плейлист был изменен
  }

  play(message) {
    if (message && message?._id && message?._id !== this.currentId) {
      this.command("playlist", this.updatePlaylistWithPossibleNewChannel(message));
      this.command("play", `id:${message._id}_${IS_JINGLE}_${IS_MESSAGE}`);
    }
  }

  updatePlaylistWithPossibleNewChannel(message) {
    //
    let result = this.playlistMessages;
    if (_.size(this.playlistChannels) > 0 && message.channelId !== this.lastChannelId) {
      const { _id: messageId, channelId } = message;
      const channel = this.playlistChannels[channelId];
      // найдем джингл для данного сообщения в плейлисте
      const messageJingleId = `${message._id}_${IS_JINGLE}_${IS_MESSAGE}`;
      const messageJingleIndex = _.findIndex(
        this.playlistMessages,
        ({ id }) => id === messageJingleId
      );

      const messageIntroId = `${messageId}_${IS_INTRO}_${channelId}`;
      // возьмем следующее после джингла сообщение
      const nextMessage = this.playlistMessages[messageJingleIndex + 1];
      // если оно является вступление для канала
      const isChannelIntro = nextMessage.id === messageIntroId;
      // то добавим его в промежуток между джинглом и сообщением
      if (!isChannelIntro && channel.soundFileId) {
        const channelIntro = this.getChannelIntro(messageId, channel);
        result = [
          ..._.slice(result, 0, messageJingleIndex + 1),
          channelIntro,
          ..._.slice(result, messageJingleIndex + 1),
        ];
        // console.log("result is", result1, this.playlistChannels, messageId, messageJingleIndex);
      }
    } else if (_.size(this.playlistChannels) > 1 && message.channelId === this.lastChannelId) {
      // если значения равны, то надо проверить, что может быть лишнее интро, которое следует удалить
      const { _id: messageId, channelId } = message;
      // найдем джингл для данного сообщения в плейлисте
      const messageJingleId = `${messageId}_${IS_JINGLE}_${IS_MESSAGE}`;
      const messageJingleIndex = _.findIndex(
        this.playlistMessages,
        ({ id }) => id === messageJingleId
      );

      const messageIntroId = `${messageId}_${IS_INTRO}_${channelId}`;
      // возьмем следующее после джингла сообщение
      const nextMessage = this.playlistMessages[messageJingleIndex + 1];
      // если оно является вступление для канала
      const isChannelIntro = nextMessage.id === messageIntroId;
      // то пропустим его, чтобы не мешалось
      if (isChannelIntro) {
        result = [
          ..._.slice(result, 0, messageJingleIndex + 1),
          ..._.slice(result, messageJingleIndex + 2),
        ];
        // console.log("result is", result1, this.playlistChannels, messageId, messageJingleIndex);
      }
    }
    this.playlistModified = false; // сбросим признак "изменен плейлист" после перестройки плейлиста

    return _.cloneDeep(result);
  }

  command(...commands) {
    // пока не загружен фоновый звук, аккумулируем команды, которые будут выполнены, когда звук загрузится
    if (this.currentBgSoundLoaded || !this.bgSounds.length) {
      this.playerJs.api(...commands);
    } else {
      this.lastCommands.push(commands);
    }
  }

  destroy() {
    this.lastChannelId = null;
    this.command("destroy");
  }
}

export const usePlayer = (props) => {
  const playerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { env } = useSelector((state) => state.frontend);
  const player = useSelector((state) => state.player);
  const jingleMp3 = fileUrl(env.soundsSettings.jingleFileId, "file.mp3");
  const { finalSound, playerSpeed } = env.settings;

  const bgSounds = env.soundsBackgrounds;

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.currentAdvert = player.advert;
    }
  }, [player.advert, playerRef.current]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.currentSound = player.sound;
    }
  }, [player.sound, playerRef.current]);

  useEffect(() => {
    if (playerRef.current && player.currentCastId) {
      // визуализировать прогресс
      playerRef.current.lastMessagePlayedHandler = () => {
        const query = { castId: player.currentCastId, isFavorites: player.isFavoritePlaying };
        dispatch(
          getNextCast(
            { query },
            {
              onSuccess(cast) {
                //
                dispatch(
                  getCastMessages(
                    { query: { castId: cast._id } },
                    {
                      onSuccess(messages) {
                        const firstSound = castFirstSound(cast, messages);
                        // сформируем структуру для каналов
                        const channels = _.keyBy(cast.channels, "_id");
                        dispatch(setPlayerFavoritePlaying(cast.isFavorite));
                        dispatch(setPlayerCastId(cast._id));
                        dispatch(setPlayerChannels(channels));
                        dispatch(
                          setPlayerMessages(
                            _.map(messages, (message) => ({ ...message, castId: cast._id }))
                          )
                        );
                        dispatch(setPlayerSound(firstSound));
                        setTimeout(() => {
                          dispatch(setPlayerPlay(true));
                          navigate(`?castId=${cast._id}`);
                        }, 50);
                      },
                    }
                  )
                );
              },
              onFailure() {
                // иначе считаем, что плеер закончился, и выключим его
                playerRef.current.command("stop"); // остановим проигрывание как факт
                playerRef.current.resetPlayerHandler(); // сбросим плеер
              },
            }
          )
        );
      };
    } else if (playerRef.current) {
      playerRef.current.lastMessagePlayedHandler = () => {
        //
      };
    }
  }, [player.currentCastId, playerRef.current]);

  useEffect(() => {
    playerRef.current = new PlayerJSContainer(jingleMp3, finalSound, bgSounds);
    playerRef.current.playerSpeed = playerSpeed;

    playerRef.current.onPlayHandler = () => {
      dispatch(setPlayerPlay(true));
      dispatch(setPlayerShowChannel(null));
    };
    playerRef.current.onPauseHandler = () => {
      dispatch(setPlayerPlay(false));
    };

    // визуализировать прогресс
    playerRef.current.onProgressHandler = (progress) => dispatch(setPlayerProgress(progress));
    // сбросить объявление
    playerRef.current.resetAdvertHandler = () => {
      dispatch(setPlayerAdvert(null));
    };
    // установить активность плеера
    playerRef.current.playerDisabledHandler = (value) => {
      dispatch(setPlayerDisabled(value));
    };
    // установить активность плеера
    playerRef.current.playerCurrentCastIdHandler = (value) => {
      dispatch(setPlayerCastId(value));
    };
    // установить проигрываемый звук
    playerRef.current.setSoundHandler = (sound) => {
      dispatch(setPlayerSound(sound));
    };
    // сбросить плеер
    playerRef.current.resetPlayerHandler = () => {
      dispatch(resetPlayer());
    };
    // очистить плеер
    playerRef.current.clearPlayerHandler = () => {
      dispatch(setPlayerMessages([]));
      dispatch(setPlayerMessage(null));
    };

    // логировать действие на сервере
    playerRef.current.logHandler = (body) => {
      // console.log("log body", body);
      // dispatch(logData({ body }));
    };

    // установить текущее сообщение и отметить его прослушанным
    playerRef.current.setCurrentIdHandler = (_id) => {
      _id && dispatch(addPlaylistListenedMessages([_id]));
      dispatch(setPlayerMessage({ _id }));
      dispatch(setPlayerCurrentId(_id));
    };

    playerRef.current.sendStatsHandler = (messageId, progress) => {
      const [id, type, subType] = messageId.split("_");
      const now = new Date();
      const data =
        progress === 0
          ? { startDate: now }
          : progress < 1
          ? { progress, progressDate: now }
          : { finishDate: now, progress };
      // такое ограничение сделано для того, чтобы зарегистрировать начало прослушивания сообщения начиная с джигнла
      if ([type, subType].includes(IS_MESSAGE)) {
        const body = { messageId: id, ...data, type, subType };
        const messageRaw = playerRef.current.messageById(id);
        // в зависимости от того, принадлежит ли сообщение спецвыпуску, отправим соответствующую статистику
        if (messageRaw.specialId) {
          dispatch(sendSpecialsStats({ body }));
        } else {
          dispatch(sendMessageStats({ body }));
        }
      } else if (type === IS_ADVERT) {
        const body = { advertCompanyId: id, ...data };
        dispatch(sendAdvertStats({ body }));
      }
    };

    // загрузим вступления для объявлений
    dispatch(
      getAdvertIntros(
        {},
        {
          onSuccess(data) {
            playerRef.current.advertsIntros = data;
          },
        }
      )
    );

    window.PlayerController = playerRef.current;

    return () => {
      window.PlayerController = undefined;
    };
  }, []);

  return { playerRef };
};

export function PlayerContainer(props) {
  return <div {...props}></div>;
}
