import _ from "lodash";
import moment from "moment";

export function safeAddMessage(playerState, message) {
  if (playerState.channels[message.channelId]) {
    const { messages } = playerState;
    const firstMessage = _.first(messages);
    const lastMessage = _.last(messages);
    // если добавляемое сообщение по дате старше чем последнее сообщение в списке, то добавим новое сообщение в конце
    if (moment(lastMessage.date) < moment(message.date)) {
      return { ...playerState, messages: [...messages, message] };
    } else if (moment(firstMessage.date) < moment(message.date)) {
      // если оно младше первого сообщения в списке, то заменим его мапнув список
      return {
        ...playerState,
        messages: _.map(messages, (_message) =>
          _message._id === message._id ? message : _message,
        ),
      };
    }
  }
  return playerState;
}

// безопасно удалим сообщения из плейлиста
export function safeDeleteMessage(playerState, message) {
  if (playerState.channels[message.channelId]) {
    const { messages } = playerState;
    return {
      ...playerState,
      messages: _.filter(messages, (_message) => !message.idents.includes(_message.ident)),
    };
  }
  return playerState;
}
