import _ from "lodash";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { useToggle } from "react-use";
import { useTranslate } from "react-redux-multilingual";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { history } from "store";
import { connect, useDispatch, useSelector } from "react-redux";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { frontendApi } from "reducers/api";
import { useDemoPlay } from "components/common/player/refs/demo";
import Col3Container from "components/standart/col3Container";
import DemoChannelInfo from "./demo_info";

const { api } = frontendApi.actions;

const getChannelInfo = api.get("/channels/info");

const DemoChannelModal = (props) => {
  const location = useLocation();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [channel, setChannel] = useState(null);
  // const { channels, messages } = props;
  const { demoPlayRef } = useDemoPlay();

  useEffect(() => {
    const { alias } = qs.parse(location.search);

    if (alias) {
      dispatch(
        getChannelInfo(
          { query: { alias } },
          {
            onSuccess(body) {
              setChannel(body);
              history.replace("?");
            },
            onRequest() {},
          }
        )
      );
    } else {
      // demoPlayRef.current.hideDemoChannel();
    }
  }, [location.search]); // do this on route change

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (channel) {
      setOpenModal(true);
    }
  }, [channel]);

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  return (
    <div>
      {/* {JSON.stringify({ openModal, channel })} */}
      {openModal ? (
        <Modal wrapClassName="fullscreen-modal" toggle={closeModalHandler} isOpen={true}>
          <ModalHeader toggle={closeModalHandler}></ModalHeader>
          <ModalBody>
            <Col3Container>
              <div className="text-center">
                <DemoChannelInfo {...{ channel }} />
                <div className="mt-3">
                  <button
                    className="btn btn-solid standart-header px-3"
                    onClick={() => {
                      demoPlayRef.current.playChannel(channel);
                      closeModalHandler();
                    }}
                  >
                    <i className="fa fa-play mr-2" />
                    {translate("partner_player")}
                  </button>
                </div>
              </div>
              {/*  */}
              {/*  */}
              {/*  */}
            </Col3Container>
          </ModalBody>
        </Modal>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessionStore: state.user.session,
  env: state.frontend.env,
});
export default connect(mapStateToProps, {})(DemoChannelModal);
