import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { frontendApi } from "reducers/api";
import { Link } from "react-router-dom";
import {
  setPlaylistMessages,
  setPlaylistListenedMessages,
  setPlaylistCastId,
  setPlaylistChannels,
} from "actions";
import { ChannelBlock } from "components/standart/channelBlock";
import { LoadingIcon } from "components/standart";
import { usePageVisibility } from "react-page-visibility";

import PlayerListVisual from "./visual";

const { api } = frontendApi.actions;

const defaultLimit = 75;

// специальная обертка для сброса состояния в моменте инициации компоненты
const PlayerList = (props) => {
  const [stateReady, setStateReady] = useState(false);
  useEffect(() => {
    return () => {
      props.setPlaylistCastId(null);
      props.setPlaylistMessages([]);
      props.setPlaylistChannels([]);
      setStateReady(false);
    };
  }, []);

  useEffect(() => {
    // console.log ('wtf with props.updated', props.updated)
    props.setPlaylistCastId(null);
    props.setPlaylistMessages([]);
    props.setPlaylistChannels([]);
    setStateReady(true);
  }, [props.updated]);

  return stateReady ? <PlayerListContent {...props} /> : null;
};

const PlayerListContent = (props) => {
  const { channelsId, playlist } = props;
  const isVisible = usePageVisibility();

  const { messages, channels } = playlist;
  const translate = useTranslate();
  // const { channels, messages } = useSelector((state) => state.playlist);

  // const [channelsStruct, setChannelsStruct] = useState({});
  const { player } = props;

  const [loadedMore, setLoadedMore] = useState(false);
  const [beforeDate, setBeforeDate] = useState(null);

  const [lastMessages, setLastMessages] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const channelsIdRef = useRef([]);
  useEffect(() => {
    channelsIdRef.current = channelsId;
  }, [channelsId]);

  const loadChannels = useCallback(() => {
    props.getChannels(
      { query: { id: _.join(channelsIdRef.current, ",") } },
      {
        onSuccess(channels) {
          props.setPlaylistChannels(channels);
        },
      }
    );
  }, [channelsIdRef]);

  useEffect(() => {
    // если признак "окно в фокусе" и есть существующие загруженные сообщения
    if (isVisible && messages.length) {
      // то найдем дату из самого крайнего сообщения
      const afterDate = _.last(messages).date;
      getMessages({ afterDate }); // и загрузим сообщения после указанной даты
    }
  }, [isVisible]);

  useEffect(() => {
    beforeDate && getMessages({ beforeDate });
  }, [beforeDate]);

  const getMessages = useCallback(
    ({ beforeDate, afterDate }) => {
      let limit = props.forceLimit || defaultLimit;
      // проверяем, что у нас есть настройки избранного, и это первая загрузка сообщений
      // то установим лимит загрузки сообщений согласно количеству непрочитанного
      if (false && props.favorites?.unreadAmount && !_.size(messages)) {
        const { unreadAmount } = props.favorites;
        if (unreadAmount > 100) {
          limit = 100;
        } else if (unreadAmount > defaultLimit) {
          limit = unreadAmount;
        }
      }
      props.getChannelMessages(
        {
          query: { channelId: _.join(channelsIdRef.current, ","), beforeDate, afterDate, limit },
        },
        //
        {
          onSuccess(body) {
            setLoaded(true);
            setLoading(false);
            // если это не была дозагрука поздних сообщений, то обновим количество "последних"
            if (!afterDate) {
              setLastMessages(body);
            }
            // тут следует понимать, загрузились ли сообщения в конец или начало
            const [message] = body;
            if (message) {
              // если первое сообщение в загруженных по дате меньше, чем первое сообщение в списке
              // то добавим загруженные сообщения в начало списка
              if (moment(message.date) < moment(messages[0]?.date)) {
                props.setPlaylistMessages([...body.reverse(), ...messages]);
              } else if (moment(message.date) > moment(_.last(messages).date)) {
                // если первое сообщение в загруженном списке больше первого сообщения в имеющемся списке
                // то добавим их в конец (при этом не реверсируем список, потому что в этом случае они идут в корректном порядке)
                props.setPlaylistMessages([...messages, ...body]);
              }
            }
          },
          onRequest() {
            setLoading(true);
          },
          onFailure() {
            setLoading(false);
          },
        }
      );
    },
    [messages, channelsIdRef]
  );

  const listRef = useRef();
  const listId = "player-list-container";

  useEffect(() => {
    if (_.size(channels) && _.size(messages) && !loadedMore) {
      const playerDiv = document.getElementById(listId);
      playerDiv.scrollTop = playerDiv.scrollHeight;
    }
  }, [messages, channels]);

  const loadMoreHandler = useCallback(() => {
    setLoadedMore(true);
    setBeforeDate(messages[0].date);
  }, [messages]);

  useEffect(() => {
    loadChannels();
    setBeforeDate(moment().format());
  }, [props.updated]);

  return _.size(channels) ? (
    <div
      id={listId}
      className="player-list"
      // style={{ height: "450px", overflowY: "auto" }}
      ref={listRef}
    >
      {_.size(messages) ? (
        <>
          {/* <PlayerList messages={messages} /> */}
          {!props.forceLimit ? (
            <div className="text-center text-secondary p-2 m-2">
              {_.size(lastMessages) > 0 ? (
                <div onClick={loadMoreHandler} style={{ cursor: "pointer" }}>
                  <small className="mr-2">
                    {translate("load_more")}
                    {/* {defaultLimit} */}
                  </small>
                  {isLoading ? (
                    <i className="fa fa-spinner-third fa-spin" />
                  ) : (
                    <i className="fas fa-caret-up" />
                  )}
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
          ) : (
            <div className="text-center text-secondary p-2 m-2">
              <small>{translate("last_messages")}</small>
            </div>
          )}

          <PlayerListVisual {...{ channels, messages }} />

          {props.hideOtherChannels ? null : <SimilarChannels channelsId={channelsId} />}
        </>
      ) : (
        <>{isLoaded ? <b>{translate("empty_player_list")}</b> : <LoadingIcon />}</>
      )}
    </div>
  ) : (
    <div className="py-3">
      <LoadingIcon />
    </div>
  );
};

const getSimilarShannels = api.get("/channels/similar");

const SimilarChannels = (props) => {
  const { channelsId } = props;
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const query = { id: _.join(channelsId, ",") };

    dispatch(
      getSimilarShannels(
        { query },
        {
          onSuccess(body) {
            setChannels(body);
          },
        }
      )
    );
  }, []);

  return _.size(channels) ? (
    <div>
      <div className="large-header mt-5 mb-3">{translate("more_channels")}</div>
      <div className="standart-header mb-4">{translate("more_channels_text")}</div>
      <div className="row">
        {_.map(channels, (channel, index) => {
          return <ChannelBlock className="col-4" channel={channel} key={index} />;
        })}
      </div>
      <Link to={"/channels"} className="btn btn-solid standart-header py-3">
        {translate("all_channels")}
      </Link>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  // ...
  player: state.player,
  playlist: state.playlist,
});

export default connect(mapStateToProps, {
  getChannels: api.get("/channels"),
  getChannelMessages: api.get("/channels/messages"),
  setPlaylistListenedMessages,
  setPlaylistMessages,
  setPlaylistChannels,
  setPlaylistCastId,
})(PlayerList);
