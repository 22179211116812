import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { userApi } from "reducers/api";
import { SocketsController } from "reducers/api/sockets";
import { usePageVisibility } from "react-page-visibility";

const { api } = userApi.actions;

const FavoritesIndicator = (props) => {
  const isVisible = usePageVisibility();

  const [unreadMessages, setUnreadMessages] = useState(0);
  const [socketUpdated, setSocketUpdated] = useState(null);
  const { session } = useSelector((state) => state.user);

  const onmessage = function () {
    setSocketUpdated(new Date());
  };
  const socketsRef = useRef(new SocketsController("/stats", { onmessage }));

  const getUnreadedAmount = () => {
    if (session.account) {
      props.checkNewMessages(
        {},
        {
          onSuccess(body) {
            setUnreadMessages(_.get(body, "amount") || 0);
          },
        }
      );
    }
  };

  const connectSocket = () => {
    socketsRef.current.connect();
    setSocketUpdated(new Date());
  };

  const destroySocket = () => {
    socketsRef.current.safeClose();
  };

  useEffect(() => {
    getUnreadedAmount();
  }, [socketUpdated, session.channelsId]);

  useEffect(() => {
    try {
      if (isVisible) {
        connectSocket();
      } else {
        destroySocket();
      }
    } catch (e) {
      console.error(e);
    }
  }, [isVisible]);

  return (
    <Fragment>
      {unreadMessages ? (
        <span className="badge badge-pill badge-danger -standart-header ml-1">
          {unreadMessages > 99 ? "99+" : unreadMessages}
        </span>
      ) : null}
    </Fragment>
  );
};

export default connect(null, {
  checkNewMessages: api.get("/account/casts-favorites/unread"),
})(FavoritesIndicator);
