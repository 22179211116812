import _ from "lodash";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import ReactHtmlParser from "react-html-parser";
import PlayerList from "components/common/player/list";
import { LoadingIcon, scrollTop } from "components/standart";
import nl2br from "react-nl2br";
import SoundMessagePlayButton from "components/standart/soundMessagePlayButton";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { history } from "store";
import Typewriter from "typewriter-effect";
import FavoritesIndicator from "components/standart/indicator";
import UseCategoriesUpdates from "components/standart/categoriesUpdates";
import Col3Container from "components/standart/col3Container";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { frontendApi, userApi } from "reducers/api";

import PlaylistButtons from "./buttons";

const { session } = userApi.actions;
const { api } = frontendApi.actions;

const PlaylistSection = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  // const { socketsRef } = UseCategoriesUpdates();

  const { session, env } = props;
  const { meta = {}, landings = {}, settings = {}, playlistDefaults = [] } = env;

  const [authStack, setAuthStack] = useState([!!session.account]);
  const [isUpdated, setIsUpdated] = useState(new Date());
  const [categories, setCategories] = useState([]);
  const [channelsId, setChannelsId] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [isFavorites, setFavorites] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(undefined);
  const [favoritesId, setFavoritesId] = useState([]); // избранное пользователя
  const [categoriesId, setCategoriesId] = useState({}); // категории пользователья
  const [isReady, setIsReady] = useState(null);

  const landingData = { ...landings.index };
  const { title = "", content = "", isHtml } = { ...landingData?.intro };

  // в случае изменения location заменим в истории значение на то, которое обнаружилось в адресной строке
  useEffect(() => {
    history.replace([location.pathname, location.search].join(""));
    delayedChangeLocationSearch(qs.parse(location.search), "location");
  }, [location]);

  // отреагируем на значения учетной записи
  useEffect(() => {
    // setFavoritesId(session.account ? session.channelsId : env.playlistDefaults);
    // setFavoritesId(session.account ? session.channelsId : []);
    setCategoriesId(session.account ? _.keyBy(session.categories, "categoryId") : {});
    setAuthStack([_.last(authStack), !!session.account]); // добавим значение о том, что могло поменяться состояние авторизации
  }, [session.account]);

  // отреагируем на значения учетной записи
  useEffect(() => {
    const wasAuth = authStack[0];
    const hasAuth = authStack[1];
    // если переключилось состояние между "не авторизован" и "авторизован"
    if (!wasAuth && hasAuth) {
      emulateNavigate({});
    }
    // console.log ('authstack is', authStack);
  }, [authStack]);

  // отреагируем на значения учетной записи
  useEffect(() => {
    if (_.size(categories) && isFavorites && !_.size(favoritesId) && !currentCategory) {
      // setCategory(categories[0]);
      // setFavorites(false);
      // setCurrentCategory(categories[0]._id);
    }
  }, [categories, favoritesId, currentCategory, isFavorites]);

  const changeLocationSearch = useCallback(
    (query) => {
      const { categoryId = undefined } = query;
      setCurrentCategory(categoryId);
      setFavorites(!categoryId);
      setTimeout(() => setIsReady(true), 10);
    },
    [favoritesId]
  );

  // обработаем адресную строку с небольшой задержкой
  // сделано специально, чтобы не допустить дублирующего старта при обновлении страницы
  const changeTimeout = useRef();
  const delayedChangeLocationSearch = useCallback((...args) => {
    clearTimeout(changeTimeout.current);
    changeTimeout.current = setTimeout(() => {
      changeLocationSearch(...args);
    }, 10);
  }, []);

  useEffect(() => {
    delayedChangeLocationSearch(qs.parse(history.location.search), "history");
  }, [history.location]);

  const loadCategories = () => {
    props.getCategories(
      {},
      {
        onSuccess(body) {
          // setIsReady(true);
          setCategories(body);
        },
      }
    );
  };

  useEffect(() => {
    scrollTop();

    loadCategories();
    if (!session.account) return;
    props.getUser();
  }, []);

  useEffect(() => {
    if (isFavorites) {
      setCurrentCategory(undefined);
    } else {
      setChannelsId(undefined);
    }
  }, [isFavorites]);

  useEffect(() => {
    if (
      isReady
      // ((channelsId && !currentCategory) ||
      //   (currentCategory && !channelsId) ||
      //   (!currentCategory && !channelsId))
    ) {
      // setChannelsNew({});
      if (currentCategory) {
        loadChannelsId(currentCategory);
      } else {
        // setChannelsId(session.account ? session.channelsId : env.playlistDefaults);
        setChannelsId(env.playlistDefaults);
        // setChannelsId(session.account ? session.channelsId : []);
      }
    } else {
      setChannelsId([]);
    }
  }, [isReady, isFavorites, currentCategory, session.channelsId]);

  const setCategory = (category) => {
    emulateNavigate(category._id ? { categoryId: category._id } : {});
  };

  const loadTimeout = useRef();

  const loadChannelsId = (categoryId) => {
    const query = { categoryId };

    props.getChannelIds(
      { query },
      {
        onSuccess(body) {
          // const channelsId = _.map(body, (channel) => channel._id);
          // if (session.account) checkForNew(channelsId);
          setChannelsId(body.channelsId);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
      }
    );
  };

  useEffect(() => {
    setIsUpdated(Date.now());
  }, [channelsId, isReady]);

  const emulateNavigate = useCallback(
    (state) => {
      // const { scrollY } = window;
      // дейстие через history чтобы не допустить скролла экрана
      const search = `?${qs.stringify(state)}`;
      if (history.location.search !== search) {
        setIsReady(false); // отменим готовность, чтобы не допустить загрузки данных на факте изменений
        history.push(`/${search}`);
      }
    },
    [history]
  );

  const categoiresRef = useRef(null);

  const commonButtonClass =
    "-btn -btn-rounded -btn-sm -font-weight-normal -standart-header -border-secondary -text-secondary mr-3";
  const activeButtonClass = "text-black active font-weight-bold";

  return _.size(categories) ? (
    <>
      <UseCategoriesUpdates />
      <section className="bg-white">
        <div className="container">
          <Col3Container>
            <div className="my-auto text-center pb-4">
              <div>
                <div className="large-header">{nl2br(title)}</div>
                {_.size(settings.playlistVariants) ? (
                  <div className="large-header">
                    <Typewriter
                      options={{
                        strings: settings.playlistVariants,
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </div>
                ) : null}

                <div className="standart-header mt-3 mb-4">
                  {isHtml ? ReactHtmlParser(content) : nl2br(content)}
                  {landingData.soundMessage?.fileId ? (
                    <SoundMessagePlayButton
                      sound={landingData.soundMessage}
                      component={<i className="far fa-info-circle ml-2" />}
                    />
                  ) : null}
                </div>
                <PlaylistButtons
                  updateState={() => {
                    emulateNavigate({});
                    loadCategories();
                    categoiresRef.current.scrollTo(0, 0);
                  }}
                />
                <div className="py-3 -d-none" />
                <div
                  ref={categoiresRef}
                  className="-d-none list-group list-group-horizontal text-nowrap overflow-auto favorites-list position-relative mb-3"
                >
                  <span
                    style={{ cursor: "pointer" }}
                    className={`${commonButtonClass} ${
                      isReady && !currentCategory ? activeButtonClass : ""
                    }`}
                    // onClick={() => setCategory({})}
                    onClick={() => emulateNavigate({ everything: true })}
                  >
                    {translate("channels_hot")}
                  </span>
                  {/*
                   */}
                  {/* 

                  {_.size(favoritesId) ? (
                    <span
                      className={`${commonButtonClass} ${isFavorites ? activeButtonClass : ""}`}
                      onClick={() => setCategory({})}
                    >
                      {translate("your_playlist")}
                      <FavoritesIndicator />
                    </span>
                  ) : null}
                   */}

                  {categories.map((category, index) => {
                    const btnClass = currentCategory === category._id ? activeButtonClass : "";
                    const existsCategory = categoriesId[category._id];
                    const unreadMessages = existsCategory ? existsCategory.unreadAmount : null;
                    return existsCategory ? (
                      <span
                        className={`${commonButtonClass} ${currentCategory ? btnClass : ""}`}
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => setCategory(category)}
                      >
                        {category.name}

                        {unreadMessages ? (
                          <span className="badge badge-pill badge-danger -standart-header ml-1">
                            {unreadMessages > 99 ? "99+" : unreadMessages}
                          </span>
                        ) : null}
                      </span>
                    ) : null;
                  })}
                </div>

                <div className="border-bottom d-none" />
                {isLoading ? <LoadingIcon /> : null}

                {_.size(channelsId) > 0 ? (
                  <PlayerList
                    // favorites={session.account ? session.favorites : null}
                    // channelsId={session.account ? session.channelsId : playlistDefaults}
                    channelsId={channelsId}
                    updated={isUpdated}
                    hideOtherChannels={true}
                  />
                ) : (
                  <div className="my-4">{settings.playlistText}</div>
                )}
              </div>
            </div>
          </Col3Container>
        </div>
      </section>
    </>
  ) : (
    <center className="m-3">
      <LoadingIcon />
    </center>
  );
};

const mapStateToProps = (state) => ({
  session: state.user.session,
  env: state.frontend.env,
});

export default connect(mapStateToProps, {
  ...session.actions,
  getCategories: api.get("/channels/categories/playlist"),
  // getCategories: api.get("/channels/categories"),
  getChannelIds: api.get("/channels/playlist_ids"),
})(PlaylistSection);

