/* изменение номера телефона у пользователя 
работает следующим образом:
1) пользователь вводит номер телефона
2) на стороне сервера проверяется, что такой номер не занят
- если номер занят, то выдается сообщение об ошибке
- если номер свободен, то отправляется смс код с подтверждением
3) пользователь отправляет полученный код, если проверка успешна, то 
старый логин блокируется (удаляется?), а новый номер привязывается за текущим пользователем
*/
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import NumberFormat from "react-number-format";

import { withTranslate } from "react-redux-multilingual";
import LoginForm from "views/auth/form";
import { phoneFormat } from "components/standart";
import { userApi } from "reducers/api";
import Col3Container from "components/standart/col3Container";
const { api, session } = userApi.actions;

const Phone = (props) => {
  const { translate, sessionStore } = props;

  const confirmHandler = (req, res) => {
    props.validateCode(req, {
      ...res,
      onSuccess() {
        setOpenModal(false);
      },
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);
  useEffect(() => {
    props.getUser();
  }, [openModal]);

  return (
    <div>
      <div>
        {/* <NumberFormat displayType="text" format={phoneFormat} value={sessionStore.login.value} /> */}
        {sessionStore.login.value}
        <span className="url ml-2" onClick={toggleModal}>
          <i className="fa fa-edit mr-1" />
          {translate("edit")}
        </span>
      </div>
      {openModal && (
        <Modal
          wrapClassName="fullscreen-modal"
          centered={true}
          isOpen={openModal}
          toggle={toggleModal}
        >
          <ModalHeader toggle={toggleModal}></ModalHeader>
          <ModalBody>
            <Col3Container>
              <div className="text-center large-header mb-3">{translate("settings_new_email")}</div>
              {/* <div>{translate("settings_new_email")}</div> */}

              <LoginForm
                type={"email"}
                requestHandler={props.requestCode}
                confirmHandler={confirmHandler}
              />
            </Col3Container>
          </ModalBody>
          <ModalFooter>
            {/* 
                            <button type="button" className="btn btn-solid" onClick={save}>
                                {translate("save")}
                            </button>
                             */}
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};
const mapeStateToProps = (state) => ({
  sessionStore: state.user.session,
});
export default connect(mapeStateToProps, {
  ...session.actions,
  requestCode: api.post("/account/login/request"),
  validateCode: api.post("/account/login/confirm"),
})(withTranslate(Phone));
