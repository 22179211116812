import _ from "lodash";
import { SET_WHATSAPP_MESSAGE } from "constants/ActionTypes";

export default (state = { message: "" }, action) => {
    switch (action.type) {
        // установить сообщение по умолчанию
        case SET_WHATSAPP_MESSAGE:
            return {
                message: action.message,
            };

        default:
    }
    return state;
};
