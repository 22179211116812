import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop } from "components/standart";
import { setNavi } from "actions";
import { BlockSection, IntroSection } from "components/standart/LandingSections";
import { fileUrl } from "reducers/api";

const ChannelsLanding = (props) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);

  const { meta = {}, settings = {} } = env;

  useEffect(() => {
    scrollTop();
    props.setNavi("/");

    const startInstagramVisual = () => {
      try {
        window.instgrm.Embeds.process();
      } catch (e) {
        setTimeout(startInstagramVisual, 500);
      }
    };
    setTimeout(startInstagramVisual, 500);
  }, []);
  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.join || {};
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <IntroSection type="join" paddingTop={2} paddingBottom={0}/>
      <BlockSection type="join" blockName="block1" paddingTop={4} />
      <BlockSection type="join" blockName="block2" paddingTop={4} />
      <BlockSection type="join" blockName="block3" paddingTop={4} paddingBottom={4} />
      <BlockSection
        type="join"
        // bgColor="bg-grey"
        containerClass="rounded-xl bg-grey pt-3 pb-4 px-3"
        paddingBottom={0}
        paddingTop={0}
        blockName="block4"
      />
      <BlockSection type="join" paddingTop={4} blockName="block5">
        <div className="my-4">
          <a href={settings.telegram} target="_blank" className="btn btn-solid standart-header">
            <i className="fab fa-telegram-plane mr-2" />
            {translate("send_request")}
          </a>
        </div>
      </BlockSection>
      <div className="my-4" />
    </>
  );
};

export default connect(null, {
  setNavi,
})(ChannelsLanding);
