import _ from "lodash";
import {
  RESET_PLAYER,
  SET_PLAYER_LIST,
  ADD_PLAYER_LIST,
  SET_PLAYER_MESSAGE,
  SET_PLAYER_ADVERT,
  SET_PLAYER_DISABLED,
  TOGGLE_PLAYER_PLAY,
  SET_PLAYER_PLAY,
  SET_PLAYER_VISIBLE,
  SET_PLAYER_FAVORITE_PLAYING,
  SET_PLAYER_CHANNELS,
  SET_PLAYER_PROGRESS,
  SET_PLAYER_SOUND,
  SET_PLAYER_DATA,
  SET_PLAYER_LAST_CHANNEL,
  SET_PLAYER_CAST_ID,
  SET_PLAYER_PLAYING_ID,
  SAFE_ADD_PLAYER_MESSAGE,
  SAFE_DELETE_PLAYER_MESSAGE,
  SET_PLAYER_CURRENT_ID,
  SET_SHOW_CHANNEL,
} from "constants/ActionTypes";

import { safeAddMessage, safeDeleteMessage } from "./functions";

const defaultState = {
  messages: [], // текущий список сообщений
  channels: {}, // текущие каналы
  channelsId: [], // id каналов
  message: null, // текущее сообщение
  advert: null, // текущее объявление
  sound: null, // проигрываемый в данный момент звук
  lastChannelId: null, // последний проигрываемый канал
  progress: 0, // прогресс прослушивания
  data: {}, // данные для показа/прослушивания
  visible: false, // видимость плеера
  disabled: false, // активность плеера
  play: false, // признак "плеер проигрывает звуки"
  ident: null, // идентификатора плеера
  currentId: null, // текущее проигрываемое сообщение
  currentCastId: null, // текущий проигрываемый выпуск
  showChannel: null, // показывать информацию о канале
  isFavoritePlaying: false, // проигрывается избранное
};

export default (state = { ...defaultState }, action) => {
  switch (action.type) {
    // установить содержимое плейлиста
    case RESET_PLAYER:
      return {
        ...defaultState,
        ident: action.ident || state.ident,
      };
    // добавить трек в плейлист
    // установить содержимое плейлиста
    case SET_PLAYER_LIST:
      return {
        ...state,
        messages: action.messages,
      };
    // установить содержимое плейлиста
    case SET_PLAYER_CHANNELS:
      const channels = _.keyBy(action.values, "_id");
      const channelsId = _.keys(channels).sort();
      return {
        ...state,
        channels,
        channelsId,
      };
    // добавить трек в плейлист
    case ADD_PLAYER_LIST:
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    // /*
    // установить текущий проигрываемый звук
    case SET_PLAYER_SOUND:
      return {
        ...state,
        sound: action.sound,
      };
    // установить текущее сообщение
    case SET_PLAYER_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    // установить текущее сообщение
    case SET_PLAYER_ADVERT:
      return {
        ...state,
        advert: action.advert,
      };
    // установить последний проигрываемый канал
    case SET_PLAYER_LAST_CHANNEL:
      return {
        ...state,
        lastChannelId: action.channelId,
      };
    // */
    // изменить значение проигрываемого в данный момент идентификатора
    case SET_PLAYER_CURRENT_ID:
      return {
        ...state,
        currentId: action.id,
      };
    // изменить активность кнопок
    case SET_PLAYER_DISABLED:
      return {
        ...state,
        disabled: action.value,
      };
    // установить показ карточки канала
    case SET_SHOW_CHANNEL:
      return {
        ...state,
        showChannel: action.value,
      };
    // изменить видимость плеера
    case SET_PLAYER_VISIBLE:
      return {
        ...state,
        visible: action.value,
      };
    // изменить признак "проигрывается избранное"
    case SET_PLAYER_FAVORITE_PLAYING:
      return {
        ...state,
        isFavoritePlaying: Boolean(action.value),
      };
    // переключить состояние проигрывания
    case TOGGLE_PLAYER_PLAY:
      return {
        ...state,
        play: !state.play,
      };
    // изменить состояние проигрывания
    case SET_PLAYER_PLAY:
      return {
        ...state,
        play: action.value,
      };
    // изменить прогресс плеера
    case SET_PLAYER_PROGRESS:
      return {
        ...state,
        progress: action.value,
      };
    // изменить данные для отображения
    case SET_PLAYER_DATA:
      return {
        ...state,
        data: action.data,
      };
    // изменить данные для отображения
    case SET_PLAYER_CAST_ID:
      return {
        ...state,
        currentCastId: action.castId,
      };
    // безопасно добавить сообщение в плейлист
    case SAFE_ADD_PLAYER_MESSAGE:
      return safeAddMessage(state, action.message);
    // безопасно удалить сообщения из плейлиста
    case SAFE_DELETE_PLAYER_MESSAGE:
      return safeDeleteMessage(state, action.message);
    default:
  }
  return state;
};
