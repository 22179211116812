import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { frontendApi } from "reducers/api";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { LoadingIcon, useWindowSize } from "components/standart";

import { SwiperBlock } from "components/standart/swiper-block";
import { CastInfo, CastInfoSmall, MoreCasts } from "./cast";

const { api: apiFrontend } = frontendApi.actions;

const SpecialsRecomendation = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const [blockId] = useState(Math.random().toString().substring(3, 15));
  const [query] = useState({ ...props.query, limit: 10, skipId: props.skipId });
  const [castsList, setCastsList] = useState([]);
  const [castsLoading, setCastsLoading] = useState();

  useEffect(() => {
    props.getCastsList(
      { query },
      {
        onSuccess(body) {
          setCastsList(body);
          setCastsLoading(false);
        },
        onRequest() {
          setCastsLoading(true);
        },
        onFailure() {
          setCastsLoading(false);
        },
      }
    );

    // loadCastsList(0);
  }, []);

  const selectCastId = (castId) => {
    navigate(castId ? `/?specialId=${castId}` : "/?");
  };
  const size = useWindowSize();

  return castsList.length > 0 ? (
    <div className="text-center">
      <div className="text-center large-header  pb-3">{props.title}</div>

      <div>
        <SwiperBlock
          id={`recommend-${blockId}`}
          slidesPerView={size.width > 750 ? 3 : 2}
          slides={_.concat(
            props.firstBlocks || [],
            _.map(castsList, (cast, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectCastId(cast._id)}
                >
                  <CastInfoSmall
                    cast={cast}
                    key={index}
                    // showPinned={true}
                    defaultImageId={cast.channel?.fileId}
                  />
                </div>
              );
            })
          )}
        />
        <MoreCasts {...props.showMore} />
      </div>

      {/* 
      {_.map(castsList, (cast, index) => {
        return (
          <div
            className="pb-4"
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => selectCastId(cast._id)}
          >
            <CastInfo cast={cast} key={index} defaultImageId={cast.channel?.fileId}>
              <div className="py-2">
                <ShowCastDuration {...cast} />
              </div>
            </CastInfo>
          </div>
        );
        // return <div key={index}>{JSON.stringify(cast)}</div>;
      })}
      */}

      {castsLoading ? (
        <div className="text-center p-5">
          <LoadingIcon />
        </div>
      ) : null}
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  navi: state.navi,
});

export default connect(mapStateToProps, {
  getCastsList: apiFrontend.get("/specials"),
})(SpecialsRecomendation);
