import _ from "lodash";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import nl2br from "react-nl2br";
import { useTranslate } from "react-redux-multilingual";

import { frontendApi, fileUrl } from "reducers/api";
import { SmartImage, useWindowSize } from "components/standart";

import { CastInfoSmall } from "./cast";
import { SwiperBlock } from "components/standart/swiper-block";

const { api } = frontendApi.actions;

const TopCastsBlock = (props) => {
  const navigate = useNavigate();
  const translate = useTranslate();

  const { env } = useSelector((state) => state.frontend);

  const { landings = {} } = env;
  const landingData = { ...landings.index };

  const [isLoading, setLoading] = useState(false);
  const [topCasts, setTopCasts] = useState([]);
  const [topHeader, setTopHeader] = useState("");

  const size = useWindowSize();

  const selectCastId = (castId) => {
    navigate(castId ? `/?castId=${castId}` : "/?");
  };

  useEffect(() => {
    props.getTopCasts(
      { query: { topOnly: true, lastOnly: true } },
      {
        onSuccess(body) {
          setTopCasts(body);
          setTopHeader(body[0].keywords);
          setLoading(false);
        },
        onRequest() {
          setLoading(true);
        },
      }
    );
  }, []);


  const setCurrentSlideHandler = (value) => {
    // currentSlide.current = value;
    setCurrentSlide(value);
    // setTopHeader(topCasts[value].keywords);
  };
  const [currentSlide, setCurrentSlide] = useState(0);
  const SwiperContent = React.useMemo(() => {
    return (
      <SwiperBlock
        id="top-casts-block"
        slidesPerView={size.width > 750 ? 1 : 1}
        setCurrentSlideHandler={setCurrentSlideHandler}
        slides={_.map(topCasts, (cast, index) => {
          return (
            <div key={index} style={{ cursor: "pointer" }} onClick={() => selectCastId(cast._id)}>
              <CastInfoSmall
                cast={cast}
                key={index}
                showKeywords={true}
                // showFavorite={true}
                // showPinned={true}
                // defaultImageId={cast.channel?.fileId}
              >
                {/* <div className="py-2">
                            <ShowCastDuration {...cast} />
                          </div> */}
              </CastInfoSmall>
            </div>
          );
        })}
      />
    );
  }, [topCasts, size]);

  return isLoading ? null : (
    <div className="pb-3">
      <div className="text-center large-header pt-2 pb-2">Главное</div>
      {/* <div className="text-center standart-header pb-4">{_.slice(topHeader, 0, 3)}</div> */}

      {/* 
      <div className="text-center -mr-auto large-header pt-2 pb-2">{translate("top_title")}</div>
      {topCasts.length > 0 ? (
        <div className="text-center standart-header pb-4">
          {_.slice(topCasts[0].keywords, 0, 3).concat([""]).join(". ")}
        </div>
      ) : null}
      */}

      {SwiperContent}
    </div>
  );
};

export default connect(null, {
  getTopCasts: api.get("/casts"),
})(TopCastsBlock);
