import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import PlayerList from "components/common/player/list";
import { scrollTop } from "components/standart";
import Auth from "views/auth";
import Col3Container from "components/standart/col3Container";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { userApi } from "reducers/api";

import PlaylistButtons from "./buttons";

const { session } = userApi.actions;

const Playlist = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { session } = props;
  const { meta = {}, settings = {} } = props.env;
  const [isUpdated, setIsUpdated] = useState(new Date());

  useEffect(() => {
    scrollTop();
    if (!session.account) return;
    props.getUser();
  }, []);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
  } = meta.playlist || {};

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <section className="bg-white">
        <div className="container">
          <Col3Container>
            <div className="my-auto text-center pb-4">
              {session.account ? (
                <div>
                  <div className="large-header text-center -mt-3">{translate("playlist")}</div>
                  <div className="standart-header text-center mt-3 mb-4">
                    {settings.playlistText}
                  </div>
                  {_.size(session.channelsId) > 0 ? (
                    <div>
                      <PlaylistButtons updateState={() => setIsUpdated(new Date())} />

                      <PlayerList
                        favorites={session.favorites}
                        channelsId={session.channelsId}
                        updated={isUpdated}
                      />
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn btn-solid py-3 standart-header text-nowrap"
                        onClick={() => navigate("/channels")}
                      >
                        {translate("playlist_add")}
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="large-header">{translate("auth_title")}</div>
                  <Auth />
                </div>
              )}
            </div>
          </Col3Container>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  session: state.user.session,
  env: state.frontend.env,
});

export default connect(mapStateToProps, { ...session.actions })(Playlist);
