import _ from "lodash";

import * as types from "../constants/ActionTypes";

// установить сообщение по умолчанию для иконки мессенджера
export const setWhatsAppMessage = (message) => (dispatch) => {
  dispatch({
    type: types.SET_WHATSAPP_MESSAGE,
    message,
  });
};

export const clearWhatsAppMessage = () => (dispatch) => {
  dispatch({
    type: types.SET_WHATSAPP_MESSAGE,
    message: null,
  });
};

// navigation actions
export const setNavi = (url) => (dispatch) => {
  dispatch({
    type: types.SET_NAVI,
    url,
  });
};

export const resetNavi = () => (dispatch) => {
  dispatch({
    type: types.RESET_NAVI,
  });
};

export const setHeaderSpaceHeight = (headerHeight) => (dispatch) => {
  dispatch({
    type: types.SET_HEADER_HEIGHT,
    headerHeight,
  });
};

export const setBottomSpaceHeight = (bottomHeight) => (dispatch) => {
  dispatch({
    type: types.SET_BOTTOM_HEIGHT,
    bottomHeight,
  });
};

// localeId selector
export const setLocaleData = (localeData) => (dispatch) => {
  dispatch({
    type: types.SET_LOCALE_DATA,
    localeData,
  });
};

export const setLocaleCountry = (country) => (dispatch) => {
  dispatch({
    type: types.SET_LOCALE_COUNTRY,
    country,
  });
};

// сбросить плеер
export const resetPlayer = (ident) => (dispatch) => {
  dispatch({
    type: types.RESET_PLAYER,
    ident,
  });
};

// установить плейлист
export const setPlayerMessages = (messages) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_LIST,
    messages,
  });
};

// добавить трек в плейлист
export const addPlayerList = (message) => (dispatch) => {
  dispatch({
    type: types.ADD_PLAYER_LIST,
    message,
  });
};

// установить текущий трек
export const setPlayerMessage = (message) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_MESSAGE,
    message,
  });
};

// установить текущий трек
export const setPlayerCurrentId = (id) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_CURRENT_ID,
    id,
  });
};

// установить текущее объявление
export const setPlayerSound = (sound) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_SOUND,
    sound,
  });
};

// изменить признак активности
export const setPlayerDisabled = (value) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_DISABLED,
    value,
  });
};

// изменить признак "проигрывается избранное"
export const setPlayerFavoritePlaying = (value) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_FAVORITE_PLAYING,
    value,
  });
};

// изменить признак проигрывания
export const togglePlayerPlay = () => (dispatch) => {
  dispatch({
    type: types.TOGGLE_PLAYER_PLAY,
  });
};

// изменить признак проигрывания
export const setPlayerPlay = (value) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_PLAY,
    value,
  });
};

// изменить признак показа плеера
export const setPlayerVisible = (value) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_VISIBLE,
    value,
  });
};

// изменить список каналов в плеере
export const setPlayerChannels = (values) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_CHANNELS,
    values,
  });
};

// изменить прогресс плеера
export const setPlayerProgress = (value) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_PROGRESS,
    value,
  });
};

// изменить показываемый канал
export const setPlayerShowChannel = (value) => (dispatch) => {
  dispatch({
    type: types.SET_SHOW_CHANNEL,
    value,
  });
};

// изменить рекламу в плеере
export const setPlayerAdvert = (advert) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_ADVERT,
    advert,
  });
};

// изменить визуальные данные плеера
export const setPlayerData = (data) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_DATA,
    data,
  });
};

// изменить текущий выпуск в плеере
export const setPlayerCastId = (castId) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_CAST_ID,
    castId,
  });
};

// изменить текущий выпуск в плейлисте
export const setPlaylistCastId = (castId) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYLIST_CAST_ID,
    castId,
  });
};

// последнее проигрываемое сообщение
export const setPlayerLastChannel = (channelId) => (dispatch) => {
  dispatch({
    type: types.SET_PLAYER_LAST_CHANNEL,
    channelId,
  });
};

// добавить в плейлист сообщения
export const resetPlaylist = () => (dispatch) => {
  dispatch({ type: types.RESET_PLAYLIST });
};

// установить в плейлист сообщения
export const addPlaylistMessage = (message) => (dispatch) => {
  dispatch({ type: types.ADD_PLAYLIST_MESSAGE, message });
};

// установить в плейлист сообщения
export const setPlaylistMessages = (messages) => (dispatch) => {
  dispatch({ type: types.SET_PLAYLIST_MESSAGES, messages });
};

// добавить в плейлист каналы
export const setPlaylistChannels = (channels) => (dispatch) => {
  dispatch({ type: types.SET_PLAYLIST_CHANNELS, channels });
};

// установить прослушанные сообщения в плейлисте
export const setPlaylistListenedMessages = (ids) => (dispatch) => {
  dispatch({ type: types.SET_PLAYLIST_LISTENED, ids });
};

// добавить прослушанные сообщения в плейлисте
export const addPlaylistListenedMessages = (ids) => (dispatch) => {
  dispatch({ type: types.ADD_PLAYLIST_LISTENED, ids });
};

export const safeAddPlaylistMessage = (message) => (dispatch) => {
  dispatch({ type: types.SAFE_ADD_PLAYLIST_MESSAGE, message });
};

export const safeAddPlayerMessage = (message) => (dispatch) => {
  dispatch({ type: types.SAFE_ADD_PLAYER_MESSAGE, message });
};

export const safeDeletePlaylistMessages = (message) => (dispatch) => {
  dispatch({ type: types.SAFE_DELETE_PLAYLIST_MESSAGE, message });
};

export const safeDeletePlayerMessages = (message) => (dispatch) => {
  dispatch({ type: types.SAFE_DELETE_PLAYER_MESSAGE, message });
};
