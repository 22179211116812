import _ from "lodash";
import {
  RESET_PLAYLIST,
  SET_PLAYLIST_MESSAGES,
  ADD_PLAYLIST_MESSAGE,
  SET_PLAYLIST_CHANNELS,
  SET_PLAYLIST_LISTENED,
  SET_PLAYLIST_CAST_ID,
  SAFE_ADD_PLAYLIST_MESSAGE,
  SAFE_DELETE_PLAYLIST_MESSAGE,
  ADD_PLAYLIST_LISTENED,
} from "constants/ActionTypes";

import { safeAddMessage, safeDeleteMessage } from "./functions";

// видимый плейлист
const defaultState = {
  messages: [],
  channels: {},
  channelsId: [],
  listenedMessagesId: {},
  currentCastId: null,
};

export default (state = { ...defaultState }, action) => {
  switch (action.type) {
    // установить содержимое плейлиста
    case RESET_PLAYLIST:
      return {
        ...defaultState,
      };

    // установить каналы,доступные в плеере
    case SET_PLAYLIST_CHANNELS:
      const channels = _.keyBy(action.channels, "_id");
      const channelsId = _.keys(channels).sort();
      return {
        ...state,
        channels,
        channelsId,
      };
    // добавить в плейлист сообщение
    case ADD_PLAYLIST_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message],
      };
    // установить видимые сообщения в плеере
    case SET_PLAYLIST_MESSAGES:
      return {
        ...state,
        messages: action.messages,
      };
    // установить видимые сообщения в плеере
    case SET_PLAYLIST_CAST_ID:
      return {
        ...state,
        currentCastId: action.castId,
      };
    // установить прослушанные сообщения в плеере
    case SET_PLAYLIST_LISTENED: {
      const listenedMessagesId = {};
      _.map(action.ids, (id) => (listenedMessagesId[id] = true));
      return {
        ...state,
        listenedMessagesId,
      };
    }
    // добавить прослушанные сообщения в плеере
    case ADD_PLAYLIST_LISTENED: {
      const { listenedMessagesId } = state;
      _.map(action.ids, (id) => (listenedMessagesId[id] = true));
      return {
        ...state,
        listenedMessagesId,
      };
    }
    // безопасно добавить сообщение в плейлист
    case SAFE_ADD_PLAYLIST_MESSAGE:
      return safeAddMessage(state, action.message);
    // безопасно удалить сообщения из плейлиста
    case SAFE_DELETE_PLAYLIST_MESSAGE:
      return safeDeleteMessage(state, action.message);
  }
  return state;
};
