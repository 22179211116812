import _ from "lodash";
import apiClass from "./init";
const env = new apiClass();

env.actions = {
  // настройки для сайта
  settings: env.get("/settings", {
    success(state, body) {
      return { ...state, settings: body };
    },
  }),
  // настройки звуков
  soundsSettings: env.get("/sounds/settings", {
    success(state, body) {
      return { ...state, soundsSettings: body };
    },
  }),
  // фоновые звуки 
  soundsBackgrounds: env.get("/sounds/backgrounds", {
    success(state, body) {
      return { ...state, soundsBackgrounds: body };
    },
  }),
  // лендиги для сайта
  landings: env.get("/landings", {
    success(state, body) {
      return { ...state, landings: _.keyBy(body, "type") };
    },
  }),
  // метаданные для сайта
  meta: env.get("/meta", {
    success(state, body) {
      return { ...state, meta: body };
    },
  }),
  // плейлист по умолчанию
  playlistDefault: env.get("/channels/defaults", {
    success(state, body) {
      return { ...state, playlistDefaults: body.channelsId, channelsCount: body.channelsCount };
    },
  }),
};

export default env;
